import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import DeliverySheetRegistrationView, {
  DeliverySheetForm
} from '@components/views/delivery-sheet/DeliverySheetRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { setSawmillPage } from '@redux/features/sawmill/sawmillSlice';
import { setNumberOfDeliverySheets } from '@redux/features/utility/forestSlice';
import { listSize } from '@services/apis/ApiConfiguration';
import { FullAvailableDeliverySheetResourceApi } from '@services/apis/FullAvailableDeliverySheetResourceApi';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailablePileResourceApi } from '@services/apis/FullAvailablePileResourceApi';
import {
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  GetPilesByLoggingDirectionEnum,
  Logging,
  LoggingStateEnumFilterEqualsEnum,
  PageLogging,
  Pile,
  PileWoodTypeEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { resetDeliverySheetList } from '../../redux/features/delivery-sheet/deliverySheetSlice';
import {
  resetLoggingList,
  setLoggingList,
  setLoggingPage,
  setTotalPages
} from '../../redux/features/logging/loggingSlice';
import { RootState } from '../../redux/store';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function DeliverySheetRegistrationContainer({
  navigation,
  route
}: RootStackScreenProps<'DeliverySheetRegistration'>) {
  //REDUX
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxLoggingList = useSelector((state: RootState) => state.persistedReducer.logging.list);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxLoggingPage = useSelector((state: RootState) => state.persistedReducer.logging.page);
  const rdxLoggingTotalPages = useSelector((state: RootState) => state.persistedReducer.logging.totalPages);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);

  const rdxPileList = useSelector((state: RootState) => state.persistedReducer.pile.list);
  const rdxPileCompleteList = useSelector((state: RootState) => state.persistedReducer.pile.completeList);
  const rdxSawmillList = useSelector((state: RootState) => state.persistedReducer.sawmill.list);
  const rdxSawmillPage = useSelector((state: RootState) => state.persistedReducer.sawmill.page);
  const rdxSawmillTotalPages = useSelector((state: RootState) => state.persistedReducer.sawmill.totalPages);
  const dispatch = useDispatch();

  const [pileList, setPileList] = useState<Pile[]>([]);
  const deliverySheetsNumber = useSelector((state: RootState) => state.persistedReducer.forest.numberOfDeliverySheets);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useEffect(() => {
    dispatch(resetLoggingList());
  }, []);

  useEffect(() => {
    getLoggingList();
  }, [rdxLoggingPage]);

  /**
   * currentState: { equals: LoggingStateEnumFilterEqualsEnum.InProgress )
   * @param order
   */
  const getLoggingList = (order?: ButtonSelect[]): void => {
    dispatch(changeLoaderStatus(true));
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];

    FullAvailableLoggingResourceApi.getPageLoggingsForMyCompany(rdxLoggingCompleteList, {
      sort: sortBy,
      customLoggingCriteria: {
        currentState: {
          equals: LoggingStateEnumFilterEqualsEnum.InProgress
        }
      },
      page: rdxLoggingPage,
      size: listSize
    })
      .then((loggingList: PageLogging) => {
        if (loggingList) {
          dispatch(changeLoaderStatus(false));
          dispatch(setTotalPages(loggingList.totalPages!));
          dispatch(setLoggingList(loggingList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getPileList = (loggingSelected: Logging, order?: ButtonSelect[]): void => {
    let sort = order && order.length > 0 ? order[0].sort?.sortBy : 'creationDate';
    let direction = order && order.length > 0 ? order[0].sort?.direction : 'DESC';

    if (loggingSelected && loggingSelected.id) {
      dispatch(changeLoaderStatus(true));

      FullAvailablePileResourceApi.getPilesByLogging(rdxPileCompleteList, {
        loggingId: loggingSelected.id,
        sortBy: sort!,
        direction: direction! as GetPilesByLoggingDirectionEnum
      })
        .then((list: Pile[]) => {
          dispatch(changeLoaderStatus(false));

          if (list) {
            //Filtering by Timber
            let pileList = list.filter(data => data.woodType != PileWoodTypeEnum.OtherAssortment);
            setPileList(pileList);
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  };

  const submitDeliverySheetForm = (form: DeliverySheetForm): void => {
    dispatch(changeLoaderStatus(true));

    form.deliverySheet.currentState = DeliverySheetCurrentStateEnum.InProgressByForestry;
    form.deliverySheet.currentUrstammState = DeliverySheetCurrentUrstammStateEnum.InProgress;
    form.deliverySheet.forestry = { id: rdxMyCompany?.id, name: rdxMyCompany?.name };
    form.deliverySheet.logging = {
      id: form.deliverySheet.logging?.id,
      name: form.deliverySheet.logging?.name!,
      currentState: form.deliverySheet.logging?.currentState!
    };

    FullAvailableDeliverySheetResourceApi.createDeliverySheetCustom(
      rdxOfflineSyncList,
      dispatch,
      addOfflineObjectSync,
      { deliverySheetDTO: form.deliverySheet as DeliverySheetDTO }
    )
      .then(res => {
        dispatch(changeLoaderStatus(false));
        dispatch(setNumberOfDeliverySheets(deliverySheetsNumber + 1));

        if (res) {
          dispatch(resetDeliverySheetList());
          UrstammNavigationHelper.navigateToDeliverySheetList(navigation, route.params?.loggingSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getMoreItemsLogging = (): void => {
    if (rdxLoggingPage < rdxLoggingTotalPages - 1) {
      dispatch(setLoggingPage(rdxLoggingPage + 1));
    }
  };

  const getMoreItemsSawmill = (): void => {
    if (rdxSawmillPage < rdxSawmillTotalPages - 1) {
      dispatch(setSawmillPage(rdxSawmillPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'delivery_sheet_title'}
          text={i18n.t('views.delivery_sheet.delivery_sheet_registration.new_delivery_sheet')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.loggingSelected
          ? [
              {
                title: i18n.t('generics.logging'),
                value: route.params?.loggingSelected.name!
              }
            ]
          : undefined
      }
      view={
        <DeliverySheetRegistrationView
          navigation={navigation}
          submitDeliverySheetForm={(form: DeliverySheetForm) => submitDeliverySheetForm(form)}
          moreItemsLogging={getMoreItemsLogging}
          loggingList={rdxLoggingList}
          loggingSelected={route.params?.loggingSelected}
          pileList={pileList}
          pileSelected={route.params?.pileSelected}
          sawmillList={rdxSawmillList}
          moreItemsSawmill={getMoreItemsSawmill}
          getPileListByLoggingId={(loggingFromModalSelect: Logging) => getPileList(loggingFromModalSelect)}
        />
      }
    />
  );
}
