import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import PileRegistrationView, { PileForm } from '@components/views/pile/PileRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { setDeliverySheetList } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setLoggingList } from '@redux/features/logging/loggingSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { resetPileList } from '@redux/features/pile/pileSlice';
import { setNumberOfDeliverySheets, setNumberOfPiles } from '@redux/features/utility/forestSlice';
import { RootState } from '@redux/store';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailableOtherAssortmentResourceApi } from '@services/apis/FullAvailableOtherAssortmentResourceApi';
import { FullAvailablePileResourceApi } from '@services/apis/FullAvailablePileResourceApi';
import { LoggingDTO, PileWoodTypeEnum } from '@services/apis/generated';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { clearElementForRedux } from '../../utils/classes/UrstammClassMapper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function PileRegistrationContainer({ navigation, route }: RootStackScreenProps<'PileRegistration'>) {
  const dispatch = useDispatch();
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);

  const pilesNumber = useSelector((state: RootState) => state.persistedReducer.forest.numberOfPiles);
  const deliverySheetsNumber = useSelector((state: RootState) => state.persistedReducer.forest.numberOfDeliverySheets);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.new_tree_record'),
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  const submitPileForm = (pileForm: PileForm): void => {
    dispatch(changeLoaderStatus(true));
    if (pileForm.pile.logging)
      pileForm.pile.logging = {
        id: pileForm.pile.logging?.id,
        name: pileForm.pile.logging?.name,
        currentState: pileForm.pile.logging?.currentState
      };

    const pileCopy = {};
    for (let key in pileForm.pile) {
      if (key === 'otherAssortmentType' || key === 'species') continue;
      pileCopy[key] = pileForm.pile[key];
    }
    // pileCopy['logging'].company = rdxMyCompany;

    FullAvailablePileResourceApi.createPileCustom(
      rdxOfflineSyncList,
      dispatch,
      addOfflineObjectSync,
      setDeliverySheetList,
      {
        entityWithFileDTOPile: {
          entity: pileCopy,
          base64File: pileForm.base64File
        }
      }
    )
      .then(res => {
        dispatch(changeLoaderStatus(false));
        if (res) {
          dispatch(setNumberOfPiles(pilesNumber + 1));
          dispatch(setNumberOfDeliverySheets(deliverySheetsNumber + 1));
          dispatch(resetPileList());
          if (res.woodType == PileWoodTypeEnum.OtherAssortment) {
            // Updates logging in redux because volume has changed
            FullAvailableLoggingResourceApi.getLoggingCustom(rdxLoggingCompleteList, {
              id: res.logging?.id!
            })
              .then((logging: LoggingDTO) => {
                if (logging) {
                  logging = clearElementForRedux(logging);
                  dispatch(setLoggingList([logging]));
                }
              })
              .catch(async error => {
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });

            let newPile = res;
            const newLogging = { id: newPile?.logging?.id, name: newPile?.logging?.name };
            newPile.logging = {
              id: newLogging.id,
              name: newLogging.name || ''
            };

            let otherAssortment = {
              otherAssortmentType: pileForm.pile?.otherAssortmentType,
              species: pileForm.pile?.species,
              pile: newPile
            };
            FullAvailableOtherAssortmentResourceApi.createOtherAssortmentCustom(
              rdxOfflineSyncList,
              dispatch,
              addOfflineObjectSync,
              { otherAssortment: otherAssortment }
            )
              .then(res => {
                dispatch(setNumberOfPiles(pilesNumber + 1));
                UrstammNavigationHelper.navigateToPileList(navigation, route.params?.loggingSelected!, true);
              })
              .catch(async error => {
                dispatch(changeLoaderStatus(false));
                let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
                let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
                AlertHelper.showSimpleAlert('Error', errorJson);
              });
          } else {
            UrstammNavigationHelper.navigateToPileList(navigation, route.params?.loggingSelected!, true);
          }
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      title={
        <UrstammTitle
          testID={'pile_title'}
          text={i18n.t('views.pile.pile_registration.new_pile')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <PileRegistrationView
          navigation={navigation}
          loggingSelected={route.params?.loggingSelected!}
          submitPileForm={(form: any) => submitPileForm(form)}
        />
      }
    />
  );
}
