import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import DeliverySheetDetailsView, {
  DeliverySheetPhotoForm
} from '@components/views/delivery-sheet/DeliverySheetDetailsView';
import DeliverySheetDocumentUpload from '@components/views/delivery-sheet/DeliverySheetDocumentUpload';
import { DeliverySheetForm } from '@components/views/delivery-sheet/DeliverySheetRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { resetDeliverySheetList } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { setSawmillPage } from '@redux/features/sawmill/sawmillSlice';
import { resetTrunkList, setTrunkList, setTrunkListSort, TrunkListItem } from '@redux/features/trunk/trunkSlice';
import { RootState, store } from '@redux/store';
import { customDeliverySheetResourceApi } from '@services/apis/ApiConfiguration';
import { FullAvailableDeliverySheetResourceApi } from '@services/apis/FullAvailableDeliverySheetResourceApi';
import { FullAvailableTrunkResourceApi } from '@services/apis/FullAvailableTrunkResourceApi';
import {
  Company,
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  DeliverySheetErpImportation,
  DeliverySheetErpImportationDataSourceEnum,
  DeliverySheetPhoto,
  Logging,
  Pile,
  Trunk,
  UploadDeliverySheetImportFileTrunkDataSourceEnum
} from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import {
  canAddTrunksByDSCurrentState,
  dsCurrentStatusIsInProgressOrValidOrClosed,
  dsCurrentStatusIsOneOf,
  trunkChangedProperties,
  urstammUtilGoBack
} from '../../utils/classes/UrstammUtilityFunctions';
import { buildDeliverySheetPhotoUrl } from '../../utils/classes/UrstammUtilityImage';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function DeliverySheetDetailsContainer({
  navigation,
  route
}: RootStackScreenProps<'DeliverySheetDetails'>) {
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxSawmillList = useSelector((state: RootState) => state.persistedReducer.sawmill.list);
  const rdxSawmillPage = useSelector((state: RootState) => state.persistedReducer.sawmill.page);
  const rdxSawmillTotalPages = useSelector((state: RootState) => state.persistedReducer.sawmill.totalPages);

  const rdxTrunkList = useSelector((state: RootState) => state.persistedReducer.trunk.list);
  const rdxTrunkCompleteList = useSelector((state: RootState) => state.persistedReducer.trunk.completeList);
  const rdxTrunkSortBy = useSelector((state: RootState) => state.persistedReducer.trunk.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const [latestTrunk, setLatestTrunk] = useState<Trunk | undefined>(undefined);

  const [deliverySheetSelected, setDeliverySheetSelected] = useState<DeliverySheetDTO>();

  const [uriMultiple, setUriMultiple] = useState<any[]>([]);
  const [showSortBy, setShowSortBy] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const dispatch = useDispatch();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: canAddTrunksByDSCurrentState(
        route.params?.deliverySheet.currentUrstammState,
        route.params?.deliverySheet.logging?.currentState,
        rdxUserExtendedMe.type,
        route.params?.deliverySheet.recordingType
      ),
      text: i18n.t('generics.trunk'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.deliverySheet) {
      setDeliverySheetSelected(route.params?.deliverySheet);

      if (route.params?.deliverySheet?.deliverySheetPhotos) buildDsPreview();
    }
  }, [route.params?.deliverySheet]);

  useEffect(() => {
    if (
      route.params?.refreshList &&
      route.params?.deliverySheet.currentUrstammState == DeliverySheetCurrentUrstammStateEnum.InProgress
    ) {
      getTrunkListByDeliverySheet(route.params?.deliverySheet);
    }
  }, [route.params?.refreshList]);

  const buildDsPreview = async () => {
    let deliverySheetPhotos: DeliverySheetPhoto[] = route.params?.deliverySheet.deliverySheetPhotos;
    let photoUrlList: any[] = [];

    if (deliverySheetPhotos && deliverySheetPhotos.length > 0) {
      deliverySheetPhotos.forEach(async (photo, idx) => {
        let url = await buildDeliverySheetPhotoUrl(photo);
        photoUrlList.push(url);

        if (idx == deliverySheetPhotos.length - 1) setUriMultiple(photoUrlList);
      });
    }
  };

  const navigateToDeliverySheetRegistration = (): void => {
    UrstammNavigationHelper.navigateToDeliverySheetRegistration(navigation);
  };

  const uploadImage = (photo: DeliverySheetPhotoForm) => {
    dispatch(changeLoaderStatus(true));

    FullAvailableDeliverySheetResourceApi.uploadDeliverySheetPhoto(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
      id: deliverySheetSelected?.id!,
      entityWithFileDTODeliverySheetPhoto: {
        entity: {
          deliverySheet: { id: deliverySheetSelected?.id },
          latitude: Number(photo.latitude),
          longitude: Number(photo?.longitude),
          photoCreationDate: photo?.photoCreationDate
        },
        base64File: photo.base64File
      }
    })
      .then(res => {
        dispatch(changeLoaderStatus(false));
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);

  const updateDeliverySheet = (form: DeliverySheetForm) => {
    console.log('delivery');
    dispatch(changeLoaderStatus(true));

    const routeDS = route.params?.deliverySheet;
    let logging: Logging = {
      id: routeDS.logging.id,
      name: routeDS.logging.name,
      currentState: routeDS.logging.currentState
    };
    let pile: Pile = { id: routeDS.pile.id, name: routeDS.pile.name, currentState: routeDS.pile.currentState };
    let forestry: Company = { id: rdxMyCompany?.id, name: rdxMyCompany?.name }

    /**
     * Need to check if the update is done only on a single field (only amountOfTruckJourneys is supported at the moment)
     *
     * If the currentUrstammState is IN_PROGRESS the saving is done also on the state
     *   (currentState can be IN_PROGRESS_BY_FORESTRY, IN_PROGRESS_BY_SAWMILL, EDITED_BY_SAWMILL, EDITED_BY_FORESTRY)
     * If the current urstamm state is DELIVERED the saving is done on fields only
     */
    const {
      currentState: dsCurrentState = DeliverySheetCurrentStateEnum.InProgressBySawmill,
      currentUrstammState: dsCurrentUrstammState = DeliverySheetCurrentUrstammStateEnum.InProgress
    } = routeDS as DeliverySheetDTO;
    let updateOnly = dsCurrentStatusIsOneOf(
      dsCurrentUrstammState,
      DeliverySheetCurrentUrstammStateEnum.Delivered,
      DeliverySheetCurrentUrstammStateEnum.Validated
    );

    console.log('update call -> ');

    FullAvailableDeliverySheetResourceApi.updateDeliverySheetCustom(
      rdxOfflineSyncList,
      dispatch,
      addOfflineObjectSync,
      {
        id: deliverySheetSelected?.id!,
        deliverySheetDTO: {
          ...(form.deliverySheet as DeliverySheetDTO),
          logging: logging,
          pile: pile,
          forestry: forestry,
          currentUrstammState: routeDS.currentUrstammState,
          currentState: routeDS.currentState
        },
        updateOnly: updateOnly
      }
    )
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          dispatch(resetDeliverySheetList());
          UrstammNavigationHelper.navigateToDeliverySheetList(navigation, route.params?.deliverySheet?.logging, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getMoreItemsSawmill = (): void => {
    if (rdxSawmillPage < rdxSawmillTotalPages - 1) {
      dispatch(setSawmillPage(rdxSawmillPage + 1));
    }
  };

  /** Trunk  */
  useFocusEffect(
    useCallback(() => {
      if (dsCurrentStatusIsInProgressOrValidOrClosed(route.params?.deliverySheet?.currentState))
        getTrunkListByDeliverySheet(route.params?.deliverySheet, rdxTrunkSortBy);
    }, [route.params?.deliverySheet, rdxTrunkSortBy])
  );

  useEffect(() => {
    getTrunkListByDeliverySheet(route.params?.deliverySheet, rdxTrunkSortBy);
    // return () => {
    dispatch(resetTrunkList());
    // };
  }, [fileUpload]);

  const getTrunkListByDeliverySheet = (deliverySheetSelected?: DeliverySheetDTO, order?: ButtonSelect[]): void => {
    dispatch(changeLoaderStatus(true));
    let rdxUpdated = store.getState().persistedReducer.trunk.completeList;
    FullAvailableTrunkResourceApi.getTrunksByDeliverySheet(
      rdxUpdated.map(_ => _.trunk),
      { deliverySheetId: deliverySheetSelected?.id! }
    )
      .then((list: Trunk[]) => {
        if (list) {
          const newList: TrunkListItem[] = list.map(_ => {
            return {
              id: _.id,
              trunk: _,
              edited: {
                status: trunkChangedProperties(_, rdxUserExtendedMe.type),
                data: _
              }
            };
          });
          newList.sort((a, b) => a.trunk.trunkNumberFAS! - b.trunk.trunkNumberFAS!);
          dispatch(changeLoaderStatus(false));
          dispatch(setTrunkList(newList));
          if (newList.length > 0) setLatestTrunk(getLastTrunkByCreationDate(newList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   * Creating reference trunk
   */
  const getLastTrunkByCreationDate = (trunkList: TrunkListItem[]) => {
    const latestTrunk = trunkList
      .sort((a, b) => new Date(b.trunk.creationDate!).getTime() - new Date(a.trunk.creationDate!).getTime())
      .shift();
    return latestTrunk?.trunk;
  };

  const navigateToTrunkRegistration = (): void => {
    UrstammNavigationHelper.navigateToTrunkRegistration(navigation, route.params?.deliverySheet, latestTrunk);
  };

  const navigateToTrunkDetails = (trunkSelected: Trunk): void => {
    UrstammNavigationHelper.navigateToTrunkDetails(navigation, route.params?.deliverySheet, trunkSelected);
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setTrunkListSort(orderSelected));
    setShowSortBy(false);
  };

  const validateTrunkList = async (validateAllAndIgnoreChanges: boolean) => {
    let networkOk = await NetworkHelper.isNetworkOk(false);
    if (!networkOk) return;

    dispatch(changeLoaderStatus(true));
    let trunks: Set<Trunk> = validateAllAndIgnoreChanges
      ? new Set(rdxTrunkList.map(_ => _.trunk))
      : new Set(rdxTrunkList.map(_ => (_.edited.status ? _.trunk : _.edited.data)));

    customDeliverySheetResourceApi
      .validateDeliverySheet({
        id: route.params?.deliverySheet.id,
        deliverySheetValidationDTO: {
          id: route.params?.deliverySheet.id,
          trunks: trunks
        }
      })
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          dispatch(resetTrunkList());
          UrstammNavigationHelper.navigateToDeliverySheetList(navigation, route.params?.deliverySheet.logging, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const validateTrunkSwitched = (data: TrunkListItem) => {
    //Reset the trunk edited
    const indexComplete = rdxTrunkList.findIndex(object => object.trunk.id === data.trunk.id);
    const list = [...rdxTrunkList];
    const item = list[indexComplete];
    const itemEdited = {
      id: item.id,
      trunk: item.trunk,
      edited: {
        status: data.edited.status,
        data: item.edited.data
      }
    };
    list[indexComplete] = itemEdited;
    dispatch(setTrunkList(list));
  };

  /**
   * Function provides to copy the delivery sheet selected without copying entries of trunks.
   * Copy can be done by sawmill and forestry
   *
   * if the copy is made by the sawmill --> status of copy of DS is "delivered"
   * if the copy is made by the forestry --> status of copy of DS is "in progress"
   */
  const copyDeliverySheet = async (): Promise<void> => {
    let networkOk = await NetworkHelper.isNetworkOk(false);
    if (!networkOk) return;

    dispatch(changeLoaderStatus(true));

    customDeliverySheetResourceApi
      .copyDeliverySheet({ id: route.params?.deliverySheet.id })
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          dispatch(resetDeliverySheetList());
          UrstammNavigationHelper.navigateToDeliverySheetList(navigation, route.params?.deliverySheet.logging, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const importXml = (base64: string) => {
    let entityDs: DeliverySheetErpImportation = {
      id: route?.params?.deliverySheet.id,
      dataSource: DeliverySheetErpImportationDataSourceEnum.ErpLehmann
    };
    customDeliverySheetResourceApi
      .uploadDeliverySheetImportFile({
        id: route.params?.deliverySheet.id,
        trunkDataSource: UploadDeliverySheetImportFileTrunkDataSourceEnum.ErpLehmann,
        entityWithFileDTODeliverySheetErpImportation: {
          entity: entityDs,
          base64File: base64
        }
      })
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          UrstammNavigationHelper.navigateToDeliverySheetList(navigation, route.params?.deliverySheet.logging, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomRight={navigateToTrunkRegistration}
      title={
        <UrstammTitle
          testID={'delivery_sheet_details_title'}
          text={i18n.t('generics.delivery_sheet')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[
        {
          title: i18n.t('generics.name'),
          value: deliverySheetSelected?.name!
        }
      ]}
      view={
        !isValidated && fileUpload ? (
          <DeliverySheetDocumentUpload
            setFileUpload={setFileUpload}
            deliverySheetSelected={deliverySheetSelected!}
            validate={validateTrunkList}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
          />
        ) : (
          <DeliverySheetDetailsView
            navigation={navigation}
            submitDeliverySheetForm={(form: DeliverySheetForm) => updateDeliverySheet(form)}
            deliverySheetSelected={deliverySheetSelected!}
            uploadImage={(photo: DeliverySheetPhotoForm) => uploadImage(photo)}
            uriMultiple={uriMultiple}
            sawmillList={rdxSawmillList}
            moreItemsSawmill={getMoreItemsSawmill}
            trunkList={rdxTrunkList}
            showSortBy={showSortBy}
            trunkSelected={(trunkSelected: any) => navigateToTrunkDetails(trunkSelected.item)}
            validate={validateTrunkList}
            validateTrunkSwitched={(trunk: TrunkListItem) => validateTrunkSwitched(trunk)}
            applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
            closeShowSortBy={() => setShowSortBy(false)}
            copyDeliverySheetSelected={copyDeliverySheet}
            importXml={(base64: string) => importXml(base64)}
            fileUpload={fileUpload}
            setFileUpload={setFileUpload}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
          />
        )
      }
    />
  );
}
