import Sentry from '@app/sentry';
import UrstammImageLogo from '@components/images/UrstammImageLogo';
import { environment } from '@environments/environment';
import { AlertHelper } from '@helpers/AlertHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { logout } from '@helpers/StorageHelper';
import { i18n } from '@i18n/i18n';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/native';
import { clearCompanyAppearanceList } from '@redux/features/appearance/companyAppearanceSlice';
import { clearBatchList } from '@redux/features/batch/batchSlice';
import { clearCalculationList } from '@redux/features/calculation/calculationSlice';
import { clearDeliverySheetList } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderForSyncStatus } from '@redux/features/loader/loaderSlice';
import { clearLoggingList } from '@redux/features/logging/loggingSlice';
import { clearOfflineSyncList, updateOfflineObjectLastSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { clearOtherAssortmentList } from '@redux/features/otherAssortment/otherAssortmentSlice';
import { clearCompanyPacketWoodQualityList } from '@redux/features/packet/companyPacketWoodQualitySlice';
import { clearPileList } from '@redux/features/pile/pileSlice';
import { clearPlotList } from '@redux/features/plot/plotSlice';
import { clearProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import { clearIncomingProcessorShippingNoteList } from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import { clearOutgoingProcessorShippingNoteList } from '@redux/features/processor-shipping-note/outgoingProcessorShippingNoteSlice';
import { clearCompanyProductTypeList } from '@redux/features/product-type/companyProductTypeSlice';
import { clearProductList } from '@redux/features/product/productSlice';
import { clearProjectList } from '@redux/features/project/projectSlice';
import { clearCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { clearTreeList } from '@redux/features/tree/treeSlice';
import { clearTrunkList } from '@redux/features/trunk/trunkSlice';
import { resetToken } from '@redux/features/user/userSlice';
import { RootState } from '@redux/store';
import { Severity } from '@sentry/react';
import { nativeApplicationVersion, nativeBuildVersion } from 'expo-application';
import React, { useRef } from 'react';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import DrawerItemList from '../../../navigation/DrawerItemList';
import {
  loggedUserIsAProcessor,
  loggedUserIsAProjectmanager,
  loggedUserIsASawmill
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import UrstammButtonSync from '../button/UrstammButtonSync';
import UrstammIcon from '../icon/UrstammIcon';
import OfflineSyncManager from '../offlineSync/OfflineSyncManager';

export default function UrstammDrawerNavigation(props: any) {
  const user = useSelector((state: RootState) => state.persistedReducer.user.value);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxOfflineSyncLastSync = useSelector((state: RootState) => state.persistedReducer.offlineSync.lastSync);
  const offlineSyncManagerRef = useRef<any>();
  const dispatch = useDispatch();

  const urstammSupport = () => {
    props.navigation.dispatch({
      ...DrawerActions.closeDrawer()
    });

    let version = '';
    let versionString = '';
    let buildNumber = '';
    let buildNumberString = '';
    let platform = `${Platform.OS}`;
    let platformString = `\nOS: ${platform}`;

    try {
      version = nativeApplicationVersion || 'unknown';
      if (Platform.OS !== 'web') {
        versionString = `\nBuild: ${version}`;
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    try {
      buildNumber = nativeBuildVersion || 'unknown';
      if (Platform.OS !== 'web') {
        buildNumberString = `\nVersion: ${nativeBuildVersion || 'unknown'}`;
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    const now = Math.round(new Date().getTime() / 1000);
    const uniqueId = `${platform.slice(0, 1).toUpperCase()}${now}`;
    let subject = `${i18n.t('email.support.subject')}`;
    if (Platform.OS === 'web') {
      subject += ` [${platform}]`;
    } else {
      subject += ` [${platform} - ${version} (${buildNumber})]`;
    }
    let body = `${i18n.t(
      'email.support.body'
    )}\n\n\n----------${versionString}${buildNumberString}${platformString}\nId: ${uniqueId}`;
    const to = environment.supportEmail;
    subject = encodeURI(subject);
    body = encodeURI(body);
    Linking.openURL(`mailto:${to}?subject=${subject}&body=${body}`);
    Sentry.captureMessage(`Support request with requestId: ${uniqueId}`, Severity.Log);
  };

  const urstammLogout = () => {
    if (rdxOfflineSyncList.length > 0) {
      AlertHelper.showSimpleAlert('Error', 'cannot_logout_with_not_synchronized_objects');
      return;
    }
    logout();
    dispatch(resetToken());
    dispatch(clearBatchList());
    dispatch(clearCalculationList());
    dispatch(clearCompanyProductTypeList());
    dispatch(clearCompanyPacketWoodQualityList());
    dispatch(clearCompanySurfaceList());
    dispatch(clearDeliverySheetList());
    dispatch(clearLoggingList());
    dispatch(clearOtherAssortmentList());
    dispatch(clearPileList());
    dispatch(clearPlotList());
    dispatch(clearTreeList());
    dispatch(clearTrunkList());
    dispatch(clearOfflineSyncList());
    dispatch(clearProcessorBatchList());
    dispatch(clearIncomingProcessorShippingNoteList());
    dispatch(clearOutgoingProcessorShippingNoteList());
    dispatch(clearProductList());
    dispatch(clearCompanyAppearanceList());
    dispatch(clearProjectList());
  };

  const startSync = async () => {
    dispatch(changeLoaderForSyncStatus(true));
    let uploadOk = await offlineSyncManagerRef.current!.startUploadSync();
    let downloadOk = await offlineSyncManagerRef.current!.startDownloadSync();
    if (uploadOk && downloadOk) {
      dispatch(updateOfflineObjectLastSync());
      AlertHelper.showSuccessWithMessage('alerts.sync_successfully');
    } else {
      AlertHelper.showSimpleAlert('Error', 'cannot_complete_sync');
    }
    dispatch(changeLoaderForSyncStatus(false));
  };

  return (
    <DrawerContentScrollView
      {...props}
      options={{ headerShow: false }}
      style={styles.menuContainer}
      contentContainerStyle={{ flexGrow: 1 }}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <View style={{ justifyContent: 'flex-start' }}>
          <View style={[{ flex: 1, justifyContent: 'center', alignSelf: 'center' }]}>
            <UrstammImageLogo width={215} height={98} showText={true} />
          </View>
          <DrawerItemList {...props} />
          <OfflineSyncManager ref={offlineSyncManagerRef} />
          {environment.enableOfflineSync &&
          !loggedUserIsASawmill(rdxUserExtendedMe) &&
          !loggedUserIsAProcessor(rdxUserExtendedMe) &&
          !loggedUserIsAProjectmanager(rdxUserExtendedMe) ? (
            <UrstammButtonSync
              testID={'sync-button'}
              style={{
                ...lightGreenBorderWhiteBGButton,
                containerViewStyle: { marginHorizontal: 10 },
                textStyle: {
                  fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.sbaseFontSize),
                  fontFamily: UrstammFontFamily.lightFont
                }
              }}
              onSubmit={() => startSync()}
              lastSync={rdxOfflineSyncLastSync}
            />
          ) : null}
          <DrawerItem
            label={i18n.t('generics.logout')}
            onPress={() => urstammLogout()}
            labelStyle={[
              {
                fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.xbaseFontSize),
                fontFamily: UrstammFontFamily.lightFont
              }
            ]}
          />
        </View>
        <DrawerItem
          icon={props => (
            <View style={styles.iconStyle}>
              <UrstammIcon
                {...props}
                name="envelope"
                size={UrstammFontsize.baseFontSize}
                color={ColorTheme.mainGreen}
              />
            </View>
          )}
          label={i18n.t('generics.support')}
          onPress={() => urstammSupport()}
          labelStyle={[
            {
              fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
              fontFamily: UrstammFontFamily.boldFont,
              color: ColorTheme.mainGreen
            }
          ]}
        />
      </View>
      <Text style={{ marginLeft: 15 }}>
        {i18n.t('generics.app_version')}: 1.30.02, {i18n.t('generics.build_version')}: 135
      </Text>
    </DrawerContentScrollView>
  );
}
const styles = StyleSheet.create({
  menuContainer: {
    flex: 1,
    backgroundColor: ColorTheme.ivory,
    padding: 16
  },
  iconStyle: {
    marginRight: -16
  }
});
function cclearIncomingProcessorShippingNoteList(): any {
  throw new Error('Function not implemented.');
}
