import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ForestState {
  numberOfTrunks: number;
  numberOfTrees: number;
  numberOfPiles: number;
  numberOfDeliverySheets: number;
}

const initialState: ForestState = {
  numberOfTrunks: 0,
  numberOfTrees: 0,
  numberOfPiles: 0,
  numberOfDeliverySheets: 0
};

const forestSlice = createSlice({
  name: 'forest',
  initialState,
  reducers: {
    setNumberOfTrunks(state, action: PayloadAction<number>) {
      state.numberOfTrunks = action.payload;
    },
    setNumberOfTrees(state, action: PayloadAction<number>) {
      state.numberOfTrees = action.payload;
    },
    setNumberOfPiles(state, action: PayloadAction<number>) {
      state.numberOfPiles = action.payload;
    },
    setNumberOfDeliverySheets(state, action: PayloadAction<number>) {
      state.numberOfDeliverySheets = action.payload;
    }
  }
});

// Export actions
export const { setNumberOfTrunks, setNumberOfTrees, setNumberOfPiles, setNumberOfDeliverySheets } = forestSlice.actions;

// Export the reducer
export default forestSlice.reducer;
