export const en = {
  server_errors: {
    cannot_send_shipping_note_without_processor: 'Cannot send shipping note without a processor.',
    cannot_take_gps_coordinates: 'Cannot take GPS coordinates. \nPlease try again.',
    location_is_required: 'Location is mandatory in order to record trees.',
    location_fine_is_required: 'You must use fine location.',
    cannot_complete_sync: 'Cannot complete sync',
    cannot_logout_with_not_synchronized_objects:
      'You cannot logout because there are offline objects not yet synchronized.',
    please_check_your_internet_connection: 'Please check your internet connection.',
    error_generic: 'Something went wrong.',
    ENTITY_NOT_FOUND: 'Entity not found',
    ERP_LEHMANN_IMPORT_PARSING_ERROR: 'Error parsing xml',
    ALREADY_ACTIVE_USER: 'Already active user',
    ILLEGAL_STATE_FOR_UPDATE: 'Illegal state for update',
    ALPHABET_LETTERS_EXHAUSTED_EXCEPTION: 'Alphabet letters exhausted exception',
    SINGLE_TREE_PHOTO_NUMBER_CANNOT_BE_NULL: 'Single tree photo number cannot be null',
    MULTIPLE_TREE_PHOTO_NUMBER_MUST_BE_NULL: 'Multiple tree photo number must be null',
    PILE_WITH_OTHER_ASSORTMENT_CANNOT_HAVE_DELIVERY_SHEETS: 'Pile with other assortment cannot have delivery sheets',
    PILE_WOOD_TYPE_CANNOT_BE_NULL: 'Pile wood type cannot be null',
    DELIVERY_SHEET_RECORDING_TYPE_CANNOT_BE_NULL: 'Delivery sheet recording type cannot be null',
    TRUNK_PHOTO_NUMBER_CANNOT_BE_NULL_FOR_SINGLE_RECORDING: 'Trunk photo number cannot be null for single recording',
    TRUNK_PHOTO_NUMBER_MUST_BE_NULL_FOR_MULTIPLE_RECORDING: 'Trunk photo number must be null for multiple recording',
    BARK_DISCOUNT_NOT_FOUND_FOR_TRUNK: 'Bark discount not found for trunk',
    LENGTH_RANGE_NOT_FOUND_FOR_TRUNK: 'Length range not found for trunk',
    DIAMETER_CLASS_NOT_FOUND_FOR_TRUNK: 'Diameter not valid',
    NO_COMPANY_FOR_USER: 'No company for user',
    CANNOT_IMPORT_IF_DELIVERY_SHEET_HAS_TRUNKS: 'Cannot import if delivery sheet has trunks',
    INVALID_IMPORT_SOURCE_FOR_DELIVERY_SHEET: 'Invalid import source for delivery sheet',
    WRONG_USER_LETTER_FOR_PHOTO_NUMBER: 'Wrong user letter for photo number',
    PERMIT_MEDIA_TYPE_CANNOT_BE_NULL: 'Permit media type cannot be null',
    TRUNKS_TO_VALIDATE_DONT_BELONG_TO_DELIVERY_SHEET: 'Trunks to validate dont belong to delivery sheet',
    CANNOT_CLOSE_LOGGING_WITH_OPEN_DELIVERY_SHEETS: 'Cannot close logging with open delivery sheets',
    CANNOT_SET_PROCESSOR_CUSTOM_NAME_IF_PROCESSOR_COMPANY_IS_DEFINED:
      'Cannot set processor custom name if processor company is defined',
    CANNOT_SEND_SHIPPING_NOTE_WITH_SENT_PACKET: 'One of the packets has already been sent with another shipping note',
    CANNOT_SEND_PROCESSOR_SHIPPING_NOTE_WITH_SENT_PRODUCT:
      'One of the products has alreaady been sent with another shipping note',
    CANNOT_CLOSE_PROJECT_WITHOUT_PHOTO: 'Cannot close the project without a photo',
    CANNOT_CLOSE_PROJECT_WITH_NOT_CLOSED_OR_SENT_PRODUCT: 'Cannot close project with open products',
    CANNOT_USE_ALREADY_USED_PRODUCT: 'One of the products has already been used in another shipping note or batch',
    CANNOT_CLOSE_PROJECT_WITHOUT_PRODUCTS: 'Cannot close project without products',
    CANNOT_SEND_SHIPPING_NOTE_WITHOUT_PACKETS: 'Cannot send shipping note without packets',
    UNIQUE_IDENTIFIER_ALREADY_EXISTS_FOR_PACKET: 'Unique identifier already exists for a packet',
    UNIQUE_IDENTIFIER_ALREADY_EXISTS_FOR_PRODUCT: 'Unique identifier already exists for a product'
  },
  error: 'Error',
  errors: {
    something_went_wrong: 'Something went wrong.'
  },
  alerts: {
    are_you_sure: 'Are you sure?',
    attention: 'Attention',
    cancel: 'Cancel',
    delete: 'Delete',
    no: 'No',
    ok: 'Ok',
    replace: 'Replace',
    success: 'Success',
    want_delete_image: 'Do you want to delete image?',
    want_replace_image: 'Do you want to replace image?',
    which_action_image: 'Which action do you want to perform?',
    yes: 'Yes',
    at_least_one_trunk: "You can't deliver a delivery sheet without at least one trunk",
    sawmill_alerts:
      'For sending a delivery sheet to the sawmill, the sawmill has to be chosen in the details of this delivery sheet',
    sync_successfully: 'Synchronized successfully!'
  },
  header: {
    welcome: 'Welcome on'
  },
  utility_components: {
    filter_button: {
      all: 'All',
      status: 'Status'
    },
    input: {
      validators: {
        email: 'Email not valid',
        first_letter_character: 'First letter must be a character',
        max_length: 'The minimum length for this field is {{char}} characters',
        min_length: 'The minimum length for this field is {{char}} characters',
        numeric: 'The field must contains only numeric values',
        numeric_no_digits: 'The field must contains only numeric values (no digits)',
        required: 'The field is required',
        maxDigits: 'Value not permitted',
        percentage: 'The number must be between 0 and 100',
        password: 'Password must have at least: 8 characters, a capital letter, a number and a special character',
        maxValue: 'The maximum allowed value is {{char}}',
        minValue: 'The minimum allowed value is {{char}}'
      }
    },
    modal: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      sortBy: {
        apply_order: 'Apply sorting',
        choose_sorting_type: 'Choose sorting type',
        clear_order: 'Clear sorting',
        date_asc: 'Oldest',
        date_desc: 'Most recent',
        name_asc: 'Name A-Z',
        name_desc: 'Name Z-A',
        id_asc: 'Ascending ID',
        id_desc: 'Descending ID'
      }
    },
    corner_button: {
      menu: 'Menu',
      back: 'Back',
      new_tree_record: 'New tree record',
      sort_by: 'Sort'
    },
    list: {
      closed: 'Closed',
      creation: 'Creation:',
      cut: 'Cut',
      delivered: 'Delivered',
      edited_by_forestry: 'Edited by forestry',
      edited_by_sawmill: 'Edited by sawmill',
      in_progress: 'In progress',
      in_progress_by_forestry: 'In progress by forestry',
      in_progress_by_sawmill: 'In progress by sawmill',
      sent_in_shipping_note: 'Sent in shipping note',
      selected_for_shipping_note: 'Selected',
      moved_to_sawmill: 'Moved to sawmill',
      phone_number: 'Phone number:',
      standing: 'Standing',
      valid: 'Valid',
      validated: 'Validated',
      sent: 'Sent',
      received: 'Received',
      accepted: 'Accepted',
      rejected: 'Rejected',
      done: 'Closed'
    },
    photo_picker: {
      delete: 'Delete',
      no_location_found: 'Waiting for the localization'
    },
    sync_button: {
      last_sync: 'Last: {{date}}',
      never_synced: '-'
    }
  },
  generics: {
    please_wait_modal: 'Uploading is in progress. Please wait some moment.',
    app_version: 'App Version',
    build_version: 'Build Version',
    support: 'Support',
    are_you_sure_change_to_closed:
      'Are you sure to change state to Closed?\nYou are not able to do any changes afterwards.',
    are_you_sure_change_to_delivered:
      'Are you sure to change state to Delivered?\nAfter sending no changes in the DS are possible.',
    are_you_sure_change_to_validated:
      'Are you sure to change state to Validated?\nYou are not able to do any changes afterwards.',
    are_you_sure_validate_all: 'Are you sure to validate all trunks?\nIf you confirm you will lose all the modifies.',
    appearance: 'Appearance',
    batch: 'Batch',
    sender: 'Sender',
    receiver: 'Receiver',
    shipment: 'Shipment',
    shipping_note: 'Shipping note',
    processor_batch: 'Processor batch',
    processor_shipping_note: 'Processor shipping note',
    incoming_shipping_note: 'Incoming processor shipping note',
    company: {
      name: 'Name',
      streetName: 'Street name',
      streetNumber: 'Street number',
      city: 'City',
      zipCode: 'Zip code',
      phoneNumber: 'Phone number',
      email: 'Email'
    },
    choose_media_type: 'Choose media type',
    choose_recording_type: 'Choose recording type',
    comment: 'Comment',
    copy_delivery_sheet: 'Copy delivery sheet',
    creation: 'Creation:',
    cutting_time: 'Cutting time',
    date: 'Date',
    delivered: 'Delivered',
    delivery_sheet: 'Delivery sheet',
    details: 'Details',
    diameter: 'Diameter',
    document: 'Document',
    download_pdf: 'Download Pdf',
    edit: 'Edit',
    energy: 'Energy',
    estimate_volume: 'Estimated volume (in m³):',
    estimated_logging_volume: 'Estimated volume (in m³)',
    estimated_volume: 'Estimated volume (in m³)',
    export_pdf: 'Export Pdf',
    export_xml: 'Export Xml',
    exposed: 'Exposed',
    filter_list: 'Filter list',
    search: 'Search',
    fire_wood: 'Fire wood',
    forestry: 'Forestry',
    gps_position: 'Gps position',
    id: 'ID',
    processor_shipping_note_id: 'Shipping Note ID',
    image: 'Image',
    import_factory_measurement: 'Import Factory Measurement',
    validate: 'Validate',
    cancel: 'Cancel',
    sync_data: 'Sync Data',
    import_xml: 'Import xml',
    import: 'Import',
    incoming_goods: 'Incoming goods',
    industrial_wood: 'Industrial wood',
    industry: 'Industry',
    insert_comment_here: 'Insert comment here',
    insert_estimated_volume_here: 'Insert estimated volume here',
    latitude: 'Latitude',
    length: 'Length',
    length_with_unit: 'Length (m)',
    logging: 'Logging',
    logout: 'Logout',
    longitude: 'Longitude',
    multiple: 'Area',
    name: 'Name',
    net_volume: 'Net volume (in m³)',
    new_tree_recording: 'New tree recording',
    offline_sync: 'Offline Synchronization',
    other_assortment: 'Other assortment',
    outgoing_goods: 'Outgoing goods',
    packet: 'Packet',
    pile: 'Pile',
    piles: 'Piles',
    plot: 'Plot',
    processor: 'Processor',
    production: 'Production',
    product: 'Product',
    productType: 'Product type',
    project: 'Project',
    projects: 'Projects',
    state: 'State',
    creationDate: 'Creation date',
    dryness: 'Dryness',
    planningDimension: 'Planning dimension',
    volume: 'Volume',
    volume_with_unit: 'Volume (m³)',
    certifications: 'Certifications',
    width: 'Width',
    width_with_unit: 'Width (mm)',
    woodQuality: 'Wood quality',
    thickness: 'Thickness',
    thickness_with_unit: 'Thickness (mm)',
    treeSpecies: 'Tree species',
    amountOfProducts: 'Amount of products',
    amount_of_product: 'Amount of products',
    amount_of_packet: 'Amount of packet',
    amountOfTruckJourneys: 'Amount of truck journeys',
    type_of_record: 'Type of record',
    quality: 'Quality',
    recording_type: 'Recording type',
    save_and_continue: 'Save and continue',
    save: 'Save',
    sawmill: 'Sawmill',
    send_feedback_to_forestry: 'Send feedback to forestry',
    send_feedback_to_sawmill: 'Send feedback to sawmill',
    send_to_sawmill: 'Send to sawmill',
    single: 'Single',
    specie: 'Specie',
    species: 'Species',
    sum_cubage: 'Volume (in m³)',
    surface: 'Surface',
    sync: 'Sync data',
    timber: 'Timber',
    total_wood: 'Total wood (in m³)',
    tree_species: 'Tree species',
    tree: 'Tree',
    trees_not_found: 'Trees not found',
    trees: 'Trees',
    trunk: 'Trunk',
    trunks: 'Trunks',
    type_of_other_assortment: 'Type of other assortment',
    upload_photo_file: 'Upload photo file',
    validate_list_and_send_to_forester: 'Validate list and send to Forester',
    validate_list: 'Validate list',
    validated: 'Validated',
    waiting_for_validation: 'Waiting for validation',
    wood_quality: 'Wood quality',
    wood_type: 'Wood type',
    tree_species_enum: {
      acer: 'Acer',
      alder: 'Alder',
      apple: 'Apple',
      arolla: 'Arolla',
      ash: 'Ash',
      beech: 'Beech',
      birch: 'Birch',
      blackPine: 'Black pine',
      black_pine: 'Black pine',
      cherryTree: 'Cherry tree',
      cherry_tree: 'Cherry tree',
      chestnut: 'Chestnut',
      douglasFire: 'Douglas fire',
      douglas_fire: 'Douglas fire',
      elm: 'Elm',
      fieldMaple: 'Field maple',
      field_maple: 'Field maple',
      fir: 'Fir',
      hornbeam: 'Hornbeam',
      larch: 'Larch',
      linden: 'Linden',
      mixedHardwood: 'Mixed hardwood',
      mixed_hardwood: 'Mixed hardwood',
      mixedSoftwood: 'Mixed softwood',
      mixed_softwood: 'Mixed softwood',
      norwayMaple: 'Norway maple',
      norway_maple: 'Norway maple',
      oak: 'Oak',
      otherHardWood: 'Other hard wood',
      other_hardWood: 'Other hard wood',
      otherSoftWood: 'Other soft wood',
      other_softWood: 'Other soft wood',
      pear: 'Pear',
      pine: 'Pine',
      redOak: 'Red oak',
      red_oak: 'Red oak',
      spruce: 'Spruce',
      sycamore: 'Sycamore',
      walnut: 'Walnut',
      whitePine: 'White pine',
      white_pine: 'White pine'
    }
  },
  views: {
    login: {
      check_email_for_resetting_password: 'Check the email for resetting password',
      confirm_password: 'Confirm',
      forgot_password: 'Forgot password',
      insert_your_email_here: 'Insert your email here',
      insert_your_password_here: 'Insert your password here',
      new_password: 'New password',
      confirm_password_here: 'Confirm password',
      login_button: 'Log in',
      login: 'Login',
      reset_password: 'Reset password',
      send_email: 'Send email'
    },
    offlineSync: {
      offlineSyncs_not_found: 'Objects to sync not found',
      offlineSyncs_title: 'Offline Synchronization',
      offlineSyncs_button: 'Sync'
    },
    signup: {
      agree_app_policy_and_term: 'Agree app Policy and Term',
      create_new_account: 'Create \nnew account',
      insert_your_email_here: 'Insert your email here',
      insert_your_name_here: 'Insert your name here',
      insert_your_surname_here: 'Insert your surname here',
      login: 'Log in',
      select_your_function: 'Select your function',
      signup: 'Sign up'
    },
    shipping_note: {
      batches_not_found: 'Batches not found',
      no_urstamm_processor: 'No Urstamm processor',
      shipping_note_not_found: 'Shipping Note not found',
      sum_volume_packets: 'Packet volume (in m³)',
      send_shipping_note: 'Send shipping note',
      are_you_sure_want_send_shipping_note:
        'Are you sure to send Shipping note? \nWhen the shipping note is sent you can no longer change anything.',

      shipping_note_registration: {
        shipping_note_name: 'Name',
        shipping_note_status: 'Status',
        choose_batch: 'Choose batch',
        choose_packets: 'Choose packets',
        choose_processor: 'Choose processor',
        delivery_sheet_selected: 'Selected',
        insert_shipping_note_date_here: 'Insert shipping note date here',
        insert_shipping_note_name_here: 'Insert shipping note name here',
        new_shipping_note: 'New Shipping Note'
      }
    },
    processor_shipping_note: {
      batches_not_found: 'Batches not found',
      no_urstamm_processor: 'No Urstamm processor',
      shipping_note_not_found: 'Shipping Note not found',
      sum_volume_products: 'Product volume (in m³)',
      send_shipping_note: 'Send shipping note',
      are_you_sure_want_send_shipping_note:
        'Are you sure to send Shipping note? \nWhen the shipping note is sent you can no longer change anything.',
      are_you_sure_want_accept_shipping_note:
        'Are you sure to accept Shipping note? \nWhen the shipping note is sent you can no longer change anything.',
      are_you_sure_want_reject_shipping_note:
        'Are you sure to reject Shipping note? \nWhen the shipping note is sent you can no longer change anything.',
      accept_shipping_note: 'Accept',
      reject_shipping_note: 'Reject',

      processor_shipping_note_registration: {
        shipping_note_name: 'Name',
        shipping_note_status: 'Status',
        choose_batch: 'Choose batch',
        choose_products: 'Choose products',
        choose_processor: 'Choose processor',
        delivery_sheet_selected: 'Selected',
        insert_shipping_note_date_here: 'Insert shipping note date here',
        insert_shipping_note_name_here: 'Insert shipping note name here',
        new_shipping_note: 'New Processor shipping Note'
      }
    },
    batch: {
      batch_not_found: 'Batches not found',
      packets: 'Packets',
      sum_volume_packets: 'Packet volume (in m³)',
      close_batch: 'Close batch',
      are_you_sure_want_close_batch:
        'Are you sure to close Batch? \nWhen the Batch is closed you can no longer change anything.',
      batch_registration: {
        batch_name: 'Name',
        batch_status: 'Status',
        choose_delivery_sheet: 'Choose delivery sheet',
        delivery_sheet_selected: 'Selected',
        insert_batch_date_here: 'Insert batch date here',
        insert_batch_name_here: 'Insert batch name here',
        insert_batch_unique_identifier_here: 'Insert batch unique identifier here',
        new_batch: 'New batch',
        unique_identifier: 'Unique identifier'
      }
    },
    processor_batch: {
      processor_batch_not_found: 'Batches not found',
      products: 'Products',
      sum_volume_products: 'Product volume (in m³)',
      close_processor_batch: 'Close batch',
      products_not_found: 'Products not found',
      are_you_sure_want_close_processor_batch:
        'Are you sure to close Batch? \nWhen the Batch is closed you can no longer change anything.',
      processor_batch_registration: {
        processor_batch_name: 'Name',
        processor_batch_status: 'Status',
        choose_processor_shipping_note: 'Choose shipping note',
        choose_products: 'Choose products of sender',
        processor_shipping_note_selected: 'Selected',
        insert_processor_batch_date_here: 'Insert batch date here',
        insert_processor_batch_name_here: 'Insert batch name here',
        insert_processor_batch_unique_identifier_here: 'Insert batch unique identifier here',
        new_processor_batch: 'New Processor batch',
        unique_identifier: 'Unique identifier'
      }
    },
    delivery_sheet: {
      delivery_sheets_not_found: 'Delivery sheets not found',
      upload_delivery_sheet_image: 'Upload delivery sheet image',
      delivery_sheet_registration: {
        attention_sawmill_cannot_be_changed_afterwards: 'Attention: sawmill cannot be changed afterwards',
        insert_delivery_sheet_date_here: 'Insert delivery sheet date here',
        insert_delivery_sheet_name_here: 'Insert delivery sheet name here',
        amount_of_truck_journeys: 'Amount of truck journeys',
        insert_amount_of_truck_journeys_here: 'Insert amount of journey here',
        new_delivery_sheet: 'New delivery sheet',
        choose_logging: 'Choose logging',
        choose_pile: 'Choose pile',
        choose_sawmill: 'Choose sawmill',
        sawmill_not_found: 'Sawmill not found'
      },
      modal_pdf_text: 'Insert notes (Optional)',
      amount_of_trunks: 'Amount of trunks',
      insert_amount_of_trunks: 'Insert amount of trunks',
      insert_volume: 'Insert volume',
      volume: 'Volume',
      xml: 'XML',
      pdf: 'PDF',
      upload_xml_document: 'Upload XML document',
      document: 'Document',
      take_photo_or_upload_pdf: 'Take photo or upload PDF',
      required_information: 'Required information',
      inputs_from_sawmill: 'Inputs from sawmill',
      tree_species: 'Tree species',
      documents: 'Documents',
      comment: 'Comment',
      download_all: 'Download all'
    },
    logging: {
      loggings_not_found: 'Loggings not found',
      logging_registration: {
        insert_logging_date_here: 'Insert logging date here',
        insert_logging_name_here: 'Insert logging name here',
        new_logging: 'New logging',
        permit: 'Permit',
        upload_permit_file: 'Upload permit file',
        logging_photo: 'Logging Photo',
      },
      logging_details: {
        are_you_sure_want_close_logging:
          'Are you sure to close Logging? \nWhen the Logging is Closed you can no longer change anything.',
        close_logging: 'Close logging',
        comment_of_the_logging: 'Comment of the logging',
        cubage_standinglist: 'Cubage standinglist',
        permit_details: 'Permit details',
        plot_details: 'Plot details'
      }
    },
    tree: {
      delete_tree: 'Delete tree',
      modify_tree: 'Modify tree',
      multiple_tree: 'Area',
      single_tree: 'Single tree',
      date: 'Date',
      tree_registration: {
        insert_tree_date_here: 'Insert tree date here',
        insert_tree_photo_number_here: 'Insert photo number here',
        new_tree: 'New tree',
        photo_number: 'Photo number',
        tree_photo: 'tree photo'
      }
    },
    trunk: {
      delete_trunk: 'Delete trunk',
      modify_trunk: 'Modify trunk',
      trunks_not_found: 'Trunks not found',
      trunk_registration: {
        new_trunk: 'Add new trunk',
        choose_photo_number: 'Choose photo number',
        trunk_number: 'Trunk no.',
        insert_trunk_number_here: 'Insert trunk number here',
        insert_trunk_length_here: 'Insert trunk length here',
        insert_trunk_diameter_here: 'Insert trunk diameter here',
        trunk_length: 'Length (m)',
        trunk_diameter: 'Diameter (cm)'
      }
    },
    packet: {
      are_you_sure_want_close_packet:
        'Are you sure to close Packet? \nWhen the Packet is closed you can no longer change anything.',
      certifications_not_found: 'Certification not found',
      packets_not_found: 'Packets not found',
      products_type_not_found: 'Products type not found',
      processors_not_found: 'Processors not found',
      surfaces_not_found: 'Surfaces not found',
      woods_quality_not_found: 'Woods quality not found',
      no_urstamm_processor: 'No Urstamm processor',
      packet_registration: {
        add_new_product: 'Add new product',
        amount_of_products: 'Amount of products',
        discount: 'Discount',
        certification: 'Certification',
        choose_certification: 'Choose certification',
        choose_processor: 'Choose processor',
        choose_product_type: 'Choose product type',
        choose_surface: 'Choose surface',
        choose_trunk: 'Choose trunk',
        choose_wood_quality: 'Choose wood quality',
        enter_processor: 'Enter processor name',
        insert_amount_of_products_here: 'Insert the amount of products here',
        insert_packet_date_here: 'Insert packet date here',
        insert_packet_discount_here: 'Insert discount for invoice ',
        insert_packet_dryness_here: 'Insert packet dryness here',
        insert_processor_name_address: 'Insert processor name/address here',
        insert_packet_length_here: 'Insert packet length here',
        insert_packet_name_here: 'Insert packet name here',
        insert_packet_planning_dimension_here: 'Insert packet planning dimension here',
        insert_packet_thickness_here: 'Insert packet thickness here',
        insert_packet_unique_identifier_here: 'Insert packet unique identifier here',
        insert_packet_width_here: 'Insert packet width here',
        insert_product_type_name_here: 'Insert product type name here',
        manage_product_type: 'Manage a product type',
        new_packet: 'New packet',
        packet_dryness: 'Dryness (+/- 2% in %)',
        packet_length: 'Length (m)',
        packet_name: 'Name',
        packet_photo: 'Packet photo',
        packet_planning_dimension: 'Planning dimension',
        packet_thickness: 'Thickness (mm)',
        packet_width: 'Width (mm)',
        product_type: 'Product type',
        surface: 'Surface',
        unique_identifier: 'Unique identifier',
        update_product_type: 'Update product type'
      }
    },
    plot: {
      add_plot: 'Add plot',
      delete_plot: 'Delete plot',
      egrid_n: 'Egrid Nr.',
      insert_plot_egrid_here: 'Insert egrid number here',
      insert_plot_name_here: 'Insert plot name here',
      modify_plot: 'Save plot',
      new_plot: 'New Plot',
      plot_name: 'Plot name',
      plots_not_found: 'Plots not found'
    },
    pile: {
      choose_type_of_pile: 'Choose type of pile',
      delete_pile: 'Delete pile',
      go_to_delivery_sheets: 'Go to delivery sheets',
      list_of_pile: 'List of pile',
      piles_not_found: 'Piles not found',
      pile_not_found: 'Pile not found',
      upload_pile_file: 'Upload pile image',
      pile_registration: {
        insert_pile_date_here: 'Insert pile date here',
        insert_pile_name_here: 'Insert pile name here',
        new_pile: 'New pile',
        pile_photo: 'Pile photo'
      }
    },
    product: {
      are_you_sure_want_close_product:
        'Are you sure to close Product? \nWhen the Product is closed you can no longer change anything.',
      certifications_not_found: 'Certification not found',
      products_not_found: 'Products not found',
      products_type_not_found: 'Products type not found',
      processors_not_found: 'Processors not found',
      surfaces_not_found: 'Surfaces not found',
      appearances_not_found: 'Appearances not found',
      woods_quality_not_found: 'Woods quality not found',
      no_urstamm_processor: 'No Urstamm processor',
      product_registration: {
        add_new_product: 'Add new product',
        amount_of_products: 'Amount of products',
        discount: 'Discount',
        certification: 'Certification',
        choose_certification: 'Choose certification',
        choose_processor: 'Choose processor',
        choose_product_type: 'Choose product type',
        choose_surface: 'Choose surface',
        choose_appearance: 'Choose appearance',
        choose_product: 'Choose product',
        choose_wood_quality: 'Choose wood quality',
        enter_processor: 'Enter processor name',
        insert_amount_of_products_here: 'Insert the amount of products here',
        insert_product_date_here: 'Insert product date here',
        insert_product_discount_here: 'Insert discount for invoice ',
        insert_processor_name_address: 'Insert processor name/address here',
        insert_product_name_here: 'Insert product name here',
        insert_product_unique_identifier_here: 'Insert product unique identifier here',
        insert_product_type_name_here: 'Insert product type name here',
        manage_product_type: 'Manage a product type',
        insert_sum_cubage_here: 'Insert volume here',
        new_product: 'New product',
        product_name: 'Name',
        product_photo: 'Product photo',
        product_type: 'Product type',
        surface: 'Surface',
        unique_identifier: 'Unique identifier',
        update_product_type: 'Update product type',
        sum_cubage: 'Volume (in m³)'
      }
    },
    project: {
      are_you_sure_want_close_project:
        'Are you sure to close Project? \nWhen the Project is closed you can no longer change anything.',
      projects_not_found: 'Projects not found',
      products_not_found: 'Products not found',
      close_project: 'Close project',
      amount_of_products: 'Amount of products',
      project_registration: {
        add_new_product: 'Add new product',
        choose_products: 'Choose products',
        products_not_found: 'Products not found',
        insert_project_date_here: 'Insert project date here',
        insert_project_name_here: 'Insert project name here',
        insert_project_unique_number_here: 'Insert project unique number here',
        new_project: 'New project',
        project_name: 'Name',
        project_photo: 'Project photo',
        unique_number: 'Unique number'
      }
    }
  },
  email: {
    support: {
      subject: 'Support request',
      body: 'Dear Urstamm team,\r\n'
    }
  }
};
