import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammCameraPicker from '@components/utility-components/file-picker/UrstammCameraPicker';
import { LocationPreviewProps, UploadType } from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { RootState } from '@redux/store';
import { customDeliverySheetResourceApi } from '@services/apis/ApiConfiguration';
import {
  DeliverySheetErpImportation,
  DeliverySheetErpImportationDataSourceEnum,
  TreeDTOSpeciesEnum,
  UploadDeliverySheetImportFileTrunkDataSourceEnum
} from '@services/apis/generated';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import * as Location from 'expo-location';
import { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Layout from '../../../config/Layout';
import { getTextByTreeOrTrunkSpecies } from '../../../utils/classes/UrstammUtilityCurrentState';
import { getTreeDTOSpeciesList, loggedUserIsASawmill } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';

export default function DeliverySheetDocumentUpload(props: {
  setFileUpload: any;
  deliverySheetSelected: any;
  validate: any;
  isValidated: any;
  setIsValidated: any;
}) {
  const [isXml, setIsXml] = useState(true);
  const [treeSpeciesList, setTreeSpeciesList] = useState<SlideButtonOption[]>([]);
  const [image, setImage] = useState<string>('');
  const [isDocument, setIsDocument] = useState<boolean>(false);
  const [type, setType] = useState(ImagePicker.CameraType.back);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imageMultiple, setImageMultiple] = useStateWithCallbackLazy<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [locationPreview, setLocationPreview] = useState<LocationPreviewProps | undefined>(undefined);
  const [location, setLocation] = useState<Location.LocationObject>();
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [multiImageBase64, setMultiImageBase64] = useState<any[]>([]);
  const [multiPdfBase64, setMultiPdfBase64] = useState<any[]>([]);
  const [volume, setVolume] = useState<any>();
  const [amountOfTrunks, setAmountOfTrunks] = useState<any>();
  const [species, setSpecies] = useState<any[]>([]);
  const [comment, setComment] = useState('');
  const [validate, setValidate] = useState<boolean>(false);
  const [openCloseValidatedChangeState, setOpenCloseValidatedChangeState] = useState<boolean>(false);
  const [openCloseClosedChangeState, setOpenCloseClosedChangeState] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const dispatch = useDispatch();

  const scrollRef = useRef<any>();

  const getSlideButtonOptionsTreeSpecies = (): void => {
    let filterList: SlideButtonOption[] = [];
    (getTreeDTOSpeciesList() as TreeDTOSpeciesEnum[])
      .filter(val => val != TreeDTOSpeciesEnum.MixedSoftwood && val != TreeDTOSpeciesEnum.MixedHardwood)
      .forEach(state => {
        let type: SlideButtonOption = {
          id: state,
          text: getTextByTreeOrTrunkSpecies(state),
          active: false
        };
        filterList.push(type);
      });
    setTreeSpeciesList(filterList);
  };

  const setTreeSpecie = (selected: SlideButtonOption): void => {
    treeSpeciesList.forEach(type => {
      if (type.id === selected.id) {
        type.active = !type.active; // Toggle the active state
      }
    });
    setTreeSpeciesList([...treeSpeciesList]);
    const speciesList = treeSpeciesList.filter(item => item.active === true).map(item => item.id);
    setSpecies([...speciesList]);
  };

  useEffect(() => {
    getSlideButtonOptionsTreeSpecies();
  }, []);

  // Upload for image and pdf
  const validateForm = async () => {
    setIsLoading(true);
    setOpenCloseValidatedChangeState(false);
    customDeliverySheetResourceApi
      .validatePdfDeliverySheet({
        id: props.deliverySheetSelected?.id,
        volume: volume,
        amountOfTrunks: amountOfTrunks,
        species: species,
        comment: comment,
        deliverySheetFileUploads: multiImageBase64.length > 0 ? multiImageBase64 : multiPdfBase64
      })
      .then(res => {
        if (res) {
          setIsLoading(false);
          dispatch(changeLoaderStatus(false));
          props.validate();
          props.setFileUpload(false);
          props.setIsValidated(true);
          // props.deliverySheetSelected.currentUrstammState = DeliverySheetDTOCurrentUrstammStateEnum.Validated;
          // props.deliverySheetSelected.sentToSawmill = true;
        }
      })
      .catch(async error => {
        setIsLoading(false);
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const validateXmlForm = async () => {
    let entityDs: DeliverySheetErpImportation = {
      id: props.deliverySheetSelected.id,
      dataSource: DeliverySheetErpImportationDataSourceEnum.ErpLehmann
    };

    customDeliverySheetResourceApi
      .uploadDeliverySheetImportFile({
        id: props.deliverySheetSelected.id,
        trunkDataSource: UploadDeliverySheetImportFileTrunkDataSourceEnum.ErpLehmann,
        entityWithFileDTODeliverySheetErpImportation: {
          entity: entityDs,
          base64File: file
        }
      })
      .then(res => {
        if (res) {
          dispatch(changeLoaderStatus(false));
          props.setFileUpload(false);
          props.setIsValidated(true);
          // props.deliverySheetSelected.currentState = DeliverySheetDTOCurrentStateEnum.Valid;
          // props.deliverySheetSelected.sentToSawmill = true;
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const [file, setFile] = useState('');
  const [xmlName, setXMLName] = useState('');

  const handleWebFileUpload = async event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = e => {
      const base64 = e?.target?.result?.split(',')[1]; // Base64 encoded file content
      setFile(base64);
      setXMLName(file.name);
    };

    reader.readAsDataURL(file); // Convert file to base64
  };

  const pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'text/xml'
      });

      if (result.assets) {
        const base64 = await FileSystem.readAsStringAsync(result.assets[0].uri, {
          encoding: FileSystem.EncodingType.Base64
        });
        setFile(base64);
        setXMLName(result.assets[0].name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeFile = () => {
    setFile('');
  };

  // PDF Upload
  const uploadDocument = async () => {
    try {
      if (Platform.OS === 'web') {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'application/pdf';
        input.onchange = async event => {
          const file = event.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64 = reader.result.split(',')[1];
              const imgWithLocation = {
                latitude: location?.coords.latitude,
                longitude: location?.coords.longitude,
                fileCreationDate: new Date(),
                fileMediaType: 'PDF',
                base64File: base64
              };
              setMultiPdfBase64(prev => [...prev, imgWithLocation]);
            };
          }
        };
        input.click();
      } else {
        const result = await DocumentPicker.getDocumentAsync({
          type: 'application/pdf'
        });

        if (result.assets) {
          const base64 = await FileSystem.readAsStringAsync(result.assets[0].uri, {
            encoding: FileSystem.EncodingType.Base64
          });
          const imgWithLocation = {
            // latitude: location?.coords.latitude,
            // longitude: location?.coords.longitude,
            fileCreationDate: new Date(),
            fileMediaType: 'PDF',
            base64File: base64
          };

          setMultiPdfBase64(prev => [...prev, imgWithLocation]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showCameraModal = async () => {
    setModalVisible(true);
  };

  const closeCamera = () => {
    setModalVisible(false);
  };

  const takePhoto = async (imageCaptured: any) => {
    let imgMulti = imageCaptured.uri;
    setImageMultiple(
      prev => [...prev, imgMulti],
      () => {}
    );
    const file = await ImageManipulator.manipulateAsync(imageCaptured.uri, [], { base64: true, compress: 0.2 });

    const imgWithLocation = {
      latitude: location?.coords.latitude,
      longitude: location?.coords.longitude,
      fileCreationDate: new Date(),
      fileMediaType: 'IMAGE',
      base64File: file.base64
    };
    setMultiImageBase64(prev => [...prev, imgWithLocation]);
  };

  const showPreviewByMediaType = (data?: string) => {
    setShowPreview(true);
    setImage(data!);
  };

  const deleteFile = (element?: any, index?: any) => {
    setImageMultiple(
      prev => prev?.filter((data: any) => data != element),
      () => {}
    );
    setIsDocument(false);
    const newArray = [...multiImageBase64];
    newArray.splice(index, 1);
    setMultiImageBase64(newArray);
  };

  const deletePdfFile = (element?: any, index?: any) => {
    const newArray = [...multiPdfBase64];
    newArray.splice(index, 1);
    setMultiPdfBase64(newArray);
  };

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>{i18n.t('views.delivery_sheet.document')}</Text>
      <View style={[styles.container]}>
        <TouchableOpacity style={[isXml ? styles.activeButton : styles.normalButton]} onPress={() => setIsXml(true)}>
          <Text style={[isXml ? styles.activeText : styles.normalText]}>XML</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[isXml ? styles.normalButton : styles.activeButton]} onPress={() => setIsXml(false)}>
          <Text style={[isXml ? styles.normalText : styles.activeText]}>PDF</Text>
        </TouchableOpacity>
      </View>
      <ScrollView
        keyboardShouldPersistTaps="always"
        ref={scrollRef!}
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {isXml && (
          <View style={styles.xml_container}>
            {Platform.OS === 'web' ? (
              <>
                <input
                  type="file"
                  accept=".xml"
                  id="fileUpload"
                  style={{ display: 'none' }}
                  onChange={handleWebFileUpload}
                />
                {/* <TouchableOpacity onPress={() => document.getElementById('fileUpload')?.click()} style={styles.button}>
                  <Text style={styles.buttonText}>Upload XML File</Text>
                </TouchableOpacity> */}
                <TouchableOpacity
                  style={styles.uploadButton}
                  onPress={() => document.getElementById('fileUpload')?.click()}>
                  <Text style={styles.uploadButtonText}>{i18n.t('views.delivery_sheet.upload_xml_document')}</Text>
                  <IconButton icon="upload" iconColor="#8BC34A" size={20} style={styles.uploadIcon} />
                </TouchableOpacity>
              </>
            ) : (
              <TouchableOpacity style={styles.uploadButton} onPress={pickDocument}>
                <Text style={styles.uploadButtonText}>{i18n.t('views.delivery_sheet.upload_xml_document')}</Text>
                <IconButton icon="upload" iconColor="#8BC34A" size={20} style={styles.uploadIcon} />
              </TouchableOpacity>
            )}
            {Boolean(file) && (
              <View style={styles.fileContainer}>
                <Text style={styles.fileName}>{xmlName}</Text>
                <IconButton icon="close-circle" iconColor="#F44336" size={30} onPress={removeFile} />
              </View>
            )}
          </View>
        )}
        {!isXml && (
          <View style={{ marginBottom: 20 }}>
            <View style={UrstammStyle.flexGrow1}>
              <View style={[UrstammStyleContainer.cameraContainer]}>
                {/* File Picker */}
                <View style={[styles.uploadFileContainer]}>
                  <Text style={styles.placeholderText}>{i18n.t('views.delivery_sheet.take_photo_or_upload_pdf')}</Text>
                </View>

                <View style={[UrstammStyle.flexRow, { justifyContent: 'flex-end' }]}>
                  {/**Document */}
                  {imageMultiple.length < 1 && (
                    <TouchableOpacity style={styles.uploadFromFile} disabled={false} onPress={() => uploadDocument()}>
                      <View style={[styles.cameraButtonContainer]}>
                        <UrstammIcon
                          name={'file'}
                          size={PlatformHelper.normalizeByDevice(24)}
                          color={ColorTheme.lightGreen}
                        />
                      </View>
                    </TouchableOpacity>
                  )}
                  {/**Image */}
                  {!PlatformHelper.isWeb() && multiPdfBase64.length < 1 ? (
                    <TouchableOpacity
                      style={styles.uploadFromCamera}
                      disabled={false}
                      onPress={() => {
                        setType(
                          type === ImagePicker.CameraType.back
                            ? ImagePicker.CameraType.front
                            : ImagePicker.CameraType.back
                        );
                        showCameraModal();
                      }}>
                      <View style={[styles.cameraButtonContainer]}>
                        <UrstammIcon
                          name={'camera'}
                          size={PlatformHelper.normalizeByDevice(24)}
                          color={ColorTheme.lightGreen}
                        />
                      </View>
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>

              {/* Camera Picker */}
              {modalVisible && (
                <UrstammCameraPicker
                  testID={'logging_camera_picker'}
                  openCamera={modalVisible}
                  closeCamera={closeCamera}
                  saveImage={imageCaptured => takePhoto(imageCaptured)}
                  uploadType={UploadType.camera}
                  isLocalizationFinished={true}
                />
              )}

              {/* Managing image (or document) when it's uploaded */}
              {imageMultiple.map((data: any, idx) => {
                return (
                  <View key={idx} style={[styles.imagePreviewContainer]}>
                    {/**MULTIPLE */}

                    {/**Image preview */}
                    <TouchableOpacity style={styles.imagePreview} onPress={() => showPreviewByMediaType(data)}>
                      {/* {isAnImage() ? ( */}
                      <Image source={{ uri: data }} style={{ width: 52, height: 52 }} />
                    </TouchableOpacity>

                    {/**Delete button */}
                    <View
                      style={{
                        width: PlatformHelper.normalizeByDevice(52),
                        height: PlatformHelper.normalizeByDevice(52),
                        alignSelf: 'center'
                      }}>
                      <TouchableOpacity
                        style={[styles.deletePreviewContainerDisabled]}
                        onPress={() => deleteFile(data, idx)}>
                        <UrstammIcon name={'trash'} size={24} color={'white'} />
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}

              {multiPdfBase64.map((data: any, idx) => {
                return (
                  <View key={idx} style={[styles.imagePreviewContainer]}>
                    {/**MULTIPLE */}

                    {/**Image preview */}
                    <TouchableOpacity style={styles.imagePreview}>
                      {/* {isAnImage() ? ( */}
                      <Image
                        source={require('../../../../assets/document-pdf-icon.png')}
                        style={{ width: 52, height: 52 }}
                      />
                    </TouchableOpacity>

                    {/**Delete button */}
                    <View
                      style={{
                        width: PlatformHelper.normalizeByDevice(52),
                        height: PlatformHelper.normalizeByDevice(52),
                        alignSelf: 'center'
                      }}>
                      <TouchableOpacity
                        style={[styles.deletePreviewContainerDisabled]}
                        onPress={() => deletePdfFile(data, idx)}>
                        <UrstammIcon name={'trash'} size={24} color={'white'} />
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        )}
        <View>
          {!isXml && (
            <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>
              {i18n.t('views.delivery_sheet.required_information')}
            </Text>
          )}
          {!isXml && (
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammInput
                testID="delivery_sheet_volume"
                style={{
                  containerViewStyle: UrstammStyleContainer.inputContainer,
                  textStyle: UrstammStyle.formBaseLDarkGrey
                }}
                value={volume}
                onChangeText={(text: number) => setVolume(Number(text))}
                icon={{ showIcon: false }}
                placeholder={i18n.t('views.delivery_sheet.insert_volume')}
                textInputTitle={i18n.t('views.delivery_sheet.volume')}
                keyboardType={'numeric'}
                mandatory={!isXml}
              />
            </View>
          )}
          {!isXml && (
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammInput
                testID="delivery_sheet_amount_of_trunks"
                style={{
                  containerViewStyle: UrstammStyleContainer.inputContainer,
                  textStyle: UrstammStyle.formBaseLDarkGrey
                }}
                value={amountOfTrunks?.toString() || ''}
                onChangeText={(text: number) => setAmountOfTrunks(Number(text))}
                icon={{ showIcon: false }}
                placeholder={i18n.t('views.delivery_sheet.insert_amount_of_trunks')}
                textInputTitle={i18n.t('views.delivery_sheet.amount_of_trunks')}
                keyboardType={'numeric'}
                mandatory={!isXml}
              />
            </View>
          )}
          {!isXml && (
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammSlideButton
                testID={'button_tree_species'}
                slideButtonList={treeSpeciesList}
                slideButtonTitle={i18n.t('generics.tree_species')}
                slideButtonRotatedTitle={i18n.t('generics.species')}
                mandatory={!isXml}
                buttonPressed={(selected: SlideButtonOption) => {
                  setTreeSpecie(selected);
                }}
              />
            </View>
          )}
          {!isXml && (
            <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
              <UrstammInput
                testID="delivery_sheet_measurement_comment"
                style={{
                  containerViewStyle: UrstammStyleContainer.textareaContainer,
                  textStyle: UrstammStyle.formBaseLDarkGrey
                }}
                icon={{ showIcon: false }}
                value={comment}
                onChangeText={(text: string) => setComment(text)}
                placeholder={i18n.t('generics.insert_comment_here')}
                textInputTitle={i18n.t('generics.comment')}
                mandatory={false}
                multiline={true}
              />
            </View>
          )}
          {!isXml && (
            <View style={{ marginTop: 8 }}>
              <UrstammButtonBase
                testID={'import_delivery_sheet_xml'}
                text={i18n.t('generics.validate')}
                disabled={!volume || !amountOfTrunks || species.length < 1}
                style={mainGreenButton}
                // onSubmit={validateForm}
                onSubmit={() => {
                  setValidate(true);
                  loggedUserIsASawmill(rdxUserExtendedMe)
                    ? setOpenCloseValidatedChangeState(true)
                    : setOpenCloseClosedChangeState(true);
                }}
              />
            </View>
          )}
          {isXml && (
            <View style={{ marginTop: 8 }}>
              <UrstammButtonBase
                testID={'import_delivery_sheet_xml'}
                text={i18n.t('generics.sync_data')}
                style={mainGreenButton}
                onSubmit={validateXmlForm}
              />
            </View>
          )}
          <View style={{ marginTop: 2 }}>
            <UrstammButtonBase
              testID={'cancel_delivery_sheet_xml'}
              text={i18n.t('generics.cancel')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => props.setFileUpload(false)}
            />
          </View>
        </View>
        {openCloseValidatedChangeState && (
          <UrstammModal
            testID={'close_validate_modal'}
            text={i18n.t('generics.are_you_sure_change_to_validated')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseValidatedChangeState}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? validateForm() : null;
            }}
          />
        )}
        {isLoading && <UrstammModal
          testID={'wait_modal'}
          text={i18n.t('generics.please_wait_modal')}
          textStyle={UrstammStyleModal.formBtextModalStyle}
          visible={isLoading}
        />}
        {openCloseClosedChangeState && (
          <UrstammModal
            testID={'close_close_modal'}
            text={i18n.t('generics.are_you_sure_change_to_closed')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseClosedChangeState}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? validateForm() : null;
              confirmed ? props.validate(validate) : null, setOpenCloseClosedChangeState(false);
            }}
          />
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  cameraTopButton: {
    width: Layout.window.width * 0.9,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  web_container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  button: {
    backgroundColor: '#007bff',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    color: '#fff',
    fontSize: 16
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 20
  },
  activeButton: {
    backgroundColor: '#6F8E30',
    paddingHorizontal: 30,
    paddingVertical: 12,
    borderRadius: 50
  },
  normalButton: {
    backgroundColor: '#fff',
    borderColor: '#6F8E30',
    borderWidth: 1,
    paddingHorizontal: 30,
    paddingVertical: 12,
    borderRadius: 50
  },
  activeText: {
    color: '#fff',
    fontWeight: 'bold'
  },
  normalText: {
    color: '#6F8E30',
    fontWeight: 'bold'
  },
  xml_container: {
    marginVertical: 20
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  },
  uploadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd'
  },
  uploadButtonText: {
    flex: 1,
    color: '#333'
  },
  uploadIcon: {
    marginLeft: 10
  },
  fileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  fileName: {
    flex: 1,
    color: '#333'
  },
  placeholderText: {
    color: ColorTheme.black,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontFamily: UrstammFontFamily.lightFont,
    paddingRight: 8,
    maxWidth: PlatformHelper.normalizeByDevice(150)
  },
  uploadFileContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 64
  },
  cameraButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  uploadFromFile: {
    flex: 0.1,
    minWidth: 48,
    minHeight: 48,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: ColorTheme.darkGrey,
    marginRight: 4
  },
  uploadFromCamera: {
    flex: 0.1,
    minWidth: 48,
    minHeight: 48,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: ColorTheme.darkGrey
  },
  imagePreview: {
    flex: 1,
    justifyContent: 'center'
  },
  top_button: {
    flex: 1,
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  imagePreviewContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 4,
    padding: 12,
    borderWidth: 1,
    borderColor: ColorTheme.lightGreen,
    borderRadius: 4,
    backgroundColor: 'white'
  },
  deletePreviewContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: ColorTheme.lightGreen,
    borderRadius: 8
  },
  deletePreviewContainerDisabled: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: ColorTheme.lightGreen,
    borderRadius: 8
  }
});
