import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect, {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { i18n } from '@i18n/i18n';
import { resetBatchList } from '@redux/features/batch/batchSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  resetCompanyPacketWoodQualityList,
  setCompanyPacketWoodQualityList
} from '@redux/features/packet/companyPacketWoodQualitySlice';
import { RootState } from '@redux/store';
import {
  customBatchResourceApi,
  customCompanyPacketWoodQualityResourceApi,
  customPacketResourceApi
} from '@services/apis/ApiConfiguration';
import {
  BatchDTO,
  BatchDTORecordingTypeEnum,
  Company,
  CompanyPacketWoodQuality,
  Packet,
  PacketCertification,
  PacketCertificationCertificationEnum,
  PacketCurrentStateEnum,
  PacketDTO,
  PacketDTOCurrentStateEnum,
  PacketSpecies,
  Trunk
} from '@services/apis/generated';
import { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByTrunkState,
  getTextByTreeOrTrunkSpecies,
  getTextByTrunkState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  generateSet,
  generateSetForCertification,
  generateSetForPacketSpecies,
  getCardTitleByTrunkNumberFas,
  getCounterBySelected,
  getMandatorySymbol,
  getPacketSpeciesList,
  packetIsEditable
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildPacketPhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { PacketImageForm } from './PacketDetailsView';
import {
  CertificationProps,
  CompanyPacketWoodQualityProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  PacketForm,
  SpeciesProps,
  TrunkProps
} from './PacketRegistrationView';

export default function PacketCloseModalView(props: {
  navigation: any;
  batchDTO: BatchDTO;
  packetSelected: Packet;
  setShowPacketModal: any;
  // submitPacketForm: (data: PacketForm, packetPhoto?: PacketImageForm) => void;
  companyProductTypeProps?: CompanyProductTypeProps;
  companyPacketWoodQualityProps?: CompanyPacketWoodQualityProps;
  companySurfaceProps?: CompanySurfaceProps;
  trunkProps?: TrunkProps;
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  processorList: Company[];
  closingPacket?: boolean;
  readOnly?: boolean;
  packetClosed?: boolean;
  setPacketClosed?: any;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const rdxTrunkList = useSelector((state: RootState) => state.persistedReducer.trunk.list);
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyPacketWoodQualityList = useSelector(
    (state: RootState) => state.persistedReducer.companyPacketWoodQuality.list
  );
  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const dispatch = useDispatch();
  const [callCount, setCallCount] = useState(0);
  const [packetForm, setPacketForm] = useStateWithCallbackLazy<PacketForm>({
    packet: {
      id: props.packetSelected.id,
      uniqueIdentifier: props.packetSelected?.uniqueIdentifier ? props.packetSelected?.uniqueIdentifier : '',
      name: '',
      creationDate: new Date(),
      currentState: PacketDTOCurrentStateEnum.InProgress,
      comment: '',
      processorCustomName: ''
    }
  });
  const [photoPreview, setPhotoPreview] = useState<string | undefined>(undefined);
  const [showProductTypeModalSelect, setShowProductTypeModalSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [trunkListByBatchDSReference, setTrunkListByBatchDSReference] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDS, setTrunkListByBatchDS] = useStateWithCallbackLazy<any[]>([]);
  const [showNoUrstammProcessor, setShowNoUrstammProcessor] = useState<boolean>(false);
  const [showTrunkModalSelect, setShowTrunkModalSelect] = useState<boolean>(false);
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);
  const [showCertificationModalSelect, setShowCertificationModalSelect] = useState<boolean>(false);
  const [showPacketWoodQualityModalSelect, setShowPacketWoodQualityModalSelect] = useState<boolean>(false);
  const [speciesList, setSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [packetSpeciesList, setPacketSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [packetSpeciesListReference, setPacketSpeciesListReference] = useState<any[]>([]);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [packetImageForm, setPacketImageForm] = useStateWithCallbackLazy<PacketImageForm>({});
  const [showSurfaceModalSelect, setShowSurfaceModalSelect] = useState<boolean>(false);
  const [showClosePacketConfirm, setShowClosePacketConfirm] = useState<boolean>(false);
  const [errors, setErrors] = useStateWithCallbackLazy<any>(
    !props.closingPacket
      ? {
          name: [],
          length: [],
          width: [],
          thickness: [],
          amountOfProducts: [],
          dryness: []
        }
      : {
          name: [],
          productType: [],
          length: [],
          width: [],
          thickness: [],
          amountOfProducts: [],
          dryness: [],
          species: [],
          processor: [],
          base64File: []
        }
  );

  const validators = useState<any>(
    !props.closingPacket
      ? {
          name: { required: true },
          length: { numeric: true, max2digits: true },
          width: { numeric_no_digits: true },
          thickness: { numeric_no_digits: true },
          amountOfProducts: { numeric_no_digits: true },
          dryness: { numeric: true, percentage: true }
        }
      : {
          name: { required: true },
          productType: { required: true },
          length: { required: true, numeric: true, max2digits: true },
          width: { required: true, numeric_no_digits: true },
          thickness: { required: true, numeric_no_digits: true },
          amountOfProducts: { required: true, numeric_no_digits: true },
          dryness: { numeric: true, percentage: true },
          species: { required: true },
          processor: { required: true },
          base64File: { required: true }
        }
  );

  const [speciesReference, setSpeciesReference] = useState<string[]>([]);

  useEffect(() => {
    const newSpeciesList = [...speciesList]; // Assuming speciesList is your data source
    const speciesRef: string[] = [];

    newSpeciesList?.forEach(item => {
      if (item.active) speciesRef.push(item.id); // Assuming item.id is the string you want to push
    });

    setSpeciesReference(speciesRef); // Save the reference
  }, [speciesList]);

  const saveButtonDisabled = (errorsUpdated, updatedPacketForm?: PacketForm, updatedImageForm?: PacketImageForm) => {
    let disabled: boolean = false;
    if (
      urstammUtilValidatorInError(errorsUpdated) ||
      !updatedPacketForm?.packet.name ||
      !updatedPacketForm?.packet.length ||
      !updatedPacketForm?.packet.width ||
      !updatedPacketForm?.packet.amountOfProducts ||
      !updatedPacketForm?.packet.thickness ||
      !updatedPacketForm?.packet.processor ||
      (updatedImageForm?.base64File?.length == 0 && !photoPreview) ||
      (!updatedImageForm?.base64File && !photoPreview)
    ) {
      disabled = true;
    }

    return setSaveDisabled(disabled);
  };

  const validate = (value: string, key: string, updatedTreeForm: PacketForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, packetImageForm)
    );
  };

  const updatePacketForm = (key: string, value: any, mustValidate?: boolean): void => {
    let packet = { ...packetForm.packet };

    const formKey = key as keyof any; //TODO: add interface
    packet[formKey] = value;

    return setPacketForm(
      { packet: packet },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  useEffect(() => {
    saveButtonDisabled(errors, packetForm, packetImageForm);
  }, [photoPreview]);

  const getPacketCustom = (packetSelected: PacketDTO) => {
    dispatch(changeLoaderStatus(true));

    customPacketResourceApi
      .getPacketCustom({ id: packetSelected.id! })
      .then((packet: PacketDTO) => {
        dispatch(changeLoaderStatus(false));
        if (packet) {
          let trunksSelected = packet.trunks ? [...packet.trunks] : [];
          setPacketForm({ packet }, () => {});
          saveButtonDisabled(errors, { packet }, packetImageForm);
          if (packet?.photoFilepath) {
            buildPacketPhotoUrl(packet).then(res => {
              setPhotoPreview(res);
            });
          }
          dispatch(resetBatchList());
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyWoodQualityResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyPacketWoodQualityResourceApi
      .getAllCompanyPacketWoodQualitysForCompany({})
      .then((packetWoodQualityList: CompanyPacketWoodQuality[]) => {
        dispatch(changeLoaderStatus(false));

        if (packetWoodQualityList) {
          dispatch(resetCompanyPacketWoodQualityList());
          dispatch(setCompanyPacketWoodQualityList(packetWoodQualityList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllPacketCertifications = (packet: PacketDTO) => {
    let filterList: any[] = [];
    Object.values(PacketCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == PacketCertificationCertificationEnum.Urstamm,
        disabled: state == PacketCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let packetCertification = packet.packetCertifications ? [...packet.packetCertifications] : [];
    if (packetCertification && packetCertification.length > 0) {
      packetCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const updateProcessor = (processor: Company) => {
    if (processor.id == -200) {
      setShowProcessorModalSelect(false);
      return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    }
    packetForm.packet.processorCustomName = '';
    updatePacketForm('processor', processor, props.closingPacket);
    return setShowProcessorModalSelect(false);
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    let photo: PacketImageForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date()
    };
    setPacketImageForm(photo, packetImageFormUpdated => {
      saveButtonDisabled(errors, packetForm, packetImageFormUpdated);
    });
  };

  const deletePhoto = async () => {
    let photo: PacketImageForm = {
      base64File: undefined,
      longitude: undefined,
      latitude: undefined
    };
    setPhotoPreview(undefined);
    setPacketImageForm(photo, packetImageFormUpdated => {
      saveButtonDisabled(errors, packetForm, packetImageFormUpdated);
    });
  };

  useEffect(() => {
    getPacketCustom(packetForm.packet);
    getCompanyWoodQualityResourceApi();
    getAllPacketCertifications(packetForm.packet);
  }, []);

  useEffect(() => {
    if (callCount >= 2) return;
    const newSpecies = Array.from(new Set(packetForm.packet.packetSpecies));
    const updatedPacketSpeciesList = getPacketSpeciesList().map(item => {
      const speciesFound = newSpecies.find(speciesObj => speciesObj.species === item);
      return {
        text: getTextByTreeOrTrunkSpecies(item),
        selected: !!speciesFound,
        active: !!speciesFound,
        id: item
      };
    });
    setSpeciesList(updatedPacketSpeciesList, () => {});
    setCallCount(prevCount => prevCount + 1);
  }, [packetForm]);

  let trunkKeys: ListSubData[] = [
    {
      title: i18n.t('generics.quality') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'quality',
      keyId: '1quality',
      changedKey: 'qualityChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.length') + ' (m):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'length',
      keyId: '1length',
      changedKey: 'lengthChanged',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      changedKey: 'speciesChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.net_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'volume',
      keyId: '1netVolume',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.diameter') + ' (cm):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'diameter',
      keyId: '1diameter',
      changedKey: 'diameterChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.tree.tree_registration.photo_number') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'treePhotoNumber',
      keyId: '1treePhotoNumber',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    }
  ];

  const restoreListToReference = (setState, referenceState) => {
    const cloneList = JSON.parse(JSON.stringify(referenceState));
    setState(cloneList);
  };

  const submitPacketForm = async () => {
    try {
      dispatch(changeLoaderStatus(true));

      const certificationSet: Set<PacketCertification> = new Set(generateSetForCertification(certificationList));
      const speciesSet: Set<PacketSpecies> = generateSetForPacketSpecies(speciesList);
      const trunksSet: Set<Trunk> = new Set(generateSet(trunkListByBatchDS));

      // Create packet object
      const packetObj: Packet = {
        id: packetForm.packet?.id,
        currentState: PacketCurrentStateEnum.Closed,
        uniqueIdentifier: packetForm.packet.uniqueIdentifier!,
        name: packetForm.packet.name!,
        creationDate: packetForm.packet.creationDate,
        length: packetForm.packet.length,
        width: packetForm.packet.width,
        thickness: packetForm.packet.thickness,
        amountOfProducts: packetForm.packet.amountOfProducts,
        dryness: packetForm.packet.dryness,
        planningDimension: packetForm.packet.planningDimension,
        woodQuality: packetForm.packet.woodQuality,
        comment: packetForm.packet.comment,
        processor: packetForm.packet.processor,
        packetCertifications: certificationSet,
        packetSpecies: speciesSet,
        productType: packetForm.packet.productType,
        surface: packetForm.packet.surface,
        trunks: trunksSet,
        longitude: Number(packetImageForm?.longitude) | Number(packetForm?.packet?.longitude),
        latitude: Number(packetImageForm?.latitude) | Number(packetForm?.packet?.latitude),
        photoCreationDate: packetImageForm?.photoCreationDate,
        photoFilepath: packetForm.packet.photoFilepath == '' ? null : packetForm.packet.photoFilepath
      };

      // Await the API call
      const packet = await customPacketResourceApi.updatePacketCustom({
        id: packetForm.packet?.id!,
        entityWithFileDTOPacket: {
          entity: packetObj,
          base64File: packetImageForm.base64File == undefined ? 'null' : packetImageForm.base64File
        }
      });
      dispatch(changeLoaderStatus(false));

      if (packet) {
        props.setPacketClosed(!props.packetClosed);
        props.setShowPacketModal();
      }
    } catch (error) {
      dispatch(changeLoaderStatus(false));
      const errorMessage = await ErrorHelper.getServerMessageFromJson(error);
      const errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
      AlertHelper.showSimpleAlert('Error', errorJson);
    }
  };

  const getTrunkListByDeliverySheet = (trunksSelected?: Trunk[]): void => {
    dispatch(changeLoaderStatus(true));
    let trunkByBatchDS: Trunk[] = [];

    customBatchResourceApi
      .getDSTrunksByBatch({ id: props.batchDTO.id! })
      .then((list: Set<Trunk>) => {
        if (list) {
          trunkByBatchDS = [...list];
          setTrunkListByBatchDS(trunkByBatchDS, listUpdated => {
            deepCopyAndAddSelectedParameter(
              listUpdated,
              setTrunkListByBatchDS,
              setTrunkListByBatchDSReference,
              trunksSelected
            );
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  useEffect(() => {
    getTrunkListByDeliverySheet();
  }, []);

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Packet name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="packet_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={packetForm.packet.name ? packetForm.packet.name : ''}
            onChangeText={(text: string) => {
              updatePacketForm('name', text, true);
            }}
            placeholder={i18n.t('views.packet.packet_registration.insert_packet_name_here')}
            textInputTitle={i18n.t('views.packet.packet_registration.packet_name')}
            mandatory={true}
            editable={packetIsEditable(props.packetSelected)}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Product type list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.productType')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                packetForm.packet.productType?.name
                  ? packetForm.packet.productType?.name
                  : i18n.t('views.packet.packet_registration.choose_product_type')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProductTypeModalSelect(true)}
              disabled={!packetIsEditable(props.packetSelected)}
            />

            {/**Packet product type  */}
            {showProductTypeModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_product_type'}
                visible={showProductTypeModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_product_type'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProductTypeModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setProductType((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowProductTypeModalSelect(false), setTimeout(() => setOpenUpdateCreateProductType(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(productType: any) => {
                      updatePacketForm('productType', productType.item, props.closingPacket);
                      setShowProductTypeModalSelect(false);
                    }}
                    testID={'products_type_list'}
                    textForEmptyList={i18n.t('views.packet.products_type_not_found')}
                    list={props.companyProductTypeProps?.companyProductTypeList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companyProductTypeProps?.getMoreProductType}
                    filterList={true}
                    multipleSelection={false}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Packet length */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="length"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.length ? packetForm.packet.length.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('length', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_length_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_length')}
              keyboardType={'numeric'}
              maxLength={5}
              mandatory={props.closingPacket}
              editable={packetIsEditable(props.packetSelected)}
            />

            {/**Validator */}
            {errors['length'] && errors['length'].length > 0
              ? errors['length'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet width */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="width"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.width ? packetForm.packet.width.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('width', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_width_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_width')}
              keyboardType={'numeric'}
              mandatory={props.closingPacket}
              editable={packetIsEditable(props.packetSelected)}
            />

            {/**Validator */}
            {errors['width'] && errors['width'].length > 0
              ? errors['width'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet thickness */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="thickness"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.thickness ? packetForm.packet.thickness.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('thickness', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_thickness_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_thickness')}
              keyboardType={'numeric'}
              mandatory={props.closingPacket}
              editable={packetIsEditable(props.packetSelected)}
            />

            {/**Validator */}
            {errors['thickness'] && errors['thickness'].length > 0
              ? errors['thickness'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet amount of products sawmill */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="amountOfProducts"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={
              packetForm.packet.amountOfProducts || packetForm.packet.amountOfProducts == 0
                ? packetForm.packet.amountOfProducts.toString()
                : ''
            }
            onChangeText={(text: number) => {
              updatePacketForm('amountOfProducts', text, true);
            }}
            placeholder={i18n.t('views.packet.packet_registration.insert_amount_of_products_here')}
            textInputTitle={i18n.t('views.packet.packet_registration.amount_of_products')}
            keyboardType={'numeric'}
            mandatory={props.closingPacket}
            editable={packetIsEditable(props.packetSelected)}
          />

          {/**Validator */}
          {errors['amountOfProducts'] && errors['amountOfProducts'].length > 0
            ? errors['amountOfProducts'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Packet discount **/}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="discount"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.discount ? packetForm.packet.discount.toString() : ''}
              onChangeText={(text: string) => updatePacketForm('discount', text, false)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_discount_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.discount')}
              mandatory={false}
              editable={packetIsEditable(props.packetSelected)}
            />
          </View>
        ) : null}

        {/** Packet dryness (in %, +/- 2%) */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="dryness"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.dryness ? packetForm.packet.dryness.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('dryness', text, true)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_dryness_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_dryness')}
              keyboardType={'numeric'}
              maxLength={3}
              mandatory={false}
              editable={packetIsEditable(props.packetSelected)}
            />
            {/**Validator */}
            {errors['dryness'] && errors['dryness'].length > 0
              ? errors['dryness'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Packet Planning dimension (free text field)*/}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammInput
              testID="planning_dimension"
              style={{
                containerViewStyle: UrstammStyleContainer.inputContainer,
                textStyle: UrstammStyle.formBaseLDarkGrey
              }}
              icon={{ showIcon: false }}
              value={packetForm.packet.planningDimension ? packetForm.packet.planningDimension.toString() : ''}
              onChangeText={(text: number) => updatePacketForm('planningDimension', text)}
              placeholder={i18n.t('views.packet.packet_registration.insert_packet_planning_dimension_here')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_planning_dimension')}
              mandatory={false}
              editable={packetIsEditable(props.packetSelected)}
            />
          </View>
        ) : null}

        {/** Packet wood quality */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.woodQuality')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_wood_quality'}
              text={
                packetForm.packet.woodQuality?.name
                  ? packetForm.packet.woodQuality?.name
                  : i18n.t('views.packet.packet_registration.choose_wood_quality')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowPacketWoodQualityModalSelect(true)}
              disabled={!packetIsEditable(packetForm.packet)}
            />

            {/**Packet Modal multiple select */}
            {showPacketWoodQualityModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_choose_wood_quality'}
                visible={showPacketWoodQualityModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_wood_quality'),
                  textStyle: UrstammStyle.textTitle
                }}
                closeModal={() => {
                  setShowPacketWoodQualityModalSelect(false);
                }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(woodQuality: any) => {
                      updatePacketForm('woodQuality', woodQuality.item, false);
                      setShowPacketWoodQualityModalSelect(false);
                    }}
                    testID={'wood_quality_list'}
                    textForEmptyList={i18n.t('views.packet.woods_quality_not_found')}
                    list={rdxCompanyPacketWoodQualityList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    // moreItems={props.companyPacketWoodQualityProps?.getMorePacketWoodQuality}
                    filterList={true}
                    multipleSelection={false}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/**Packet species */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammSlideButton
              testID={'button_tree_species'}
              slideButtonList={speciesList}
              slideButtonTitle={i18n.t('generics.tree_species')}
              slideButtonRotatedTitle={i18n.t('generics.species')}
              mandatory={props.closingPacket}
              buttonPressed={(selected: SlideButtonOption) => {
                selectSlideElementFormList({ item: selected }, setSpeciesList, speciesList);
                // updatePacketForm('packetSpecies', speciesList, true);
              }}
              disabled={!packetIsEditable(props.packetSelected)}
            />
          </View>
        ) : null}

        {/** Surface list */}

        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.surface')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_surface'}
              text={
                packetForm.packet.surface?.name
                  ? packetForm.packet.surface?.name
                  : i18n.t('views.packet.packet_registration.choose_surface')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowSurfaceModalSelect(true)}
              disabled={!packetIsEditable(props.packetSelected)}
            />

            {/**Packet Modal multiple select */}
            {showSurfaceModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_choose_surface'}
                visible={showSurfaceModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_surface'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowSurfaceModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(surface: any) => {
                      updatePacketForm('surface', surface.item, false);
                      setShowSurfaceModalSelect(false);
                    }}
                    testID={'surface_list'}
                    textForEmptyList={i18n.t('views.packet.surfaces_not_found')}
                    list={rdxCompanySurfaceList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companySurfaceProps?.getMoreSurface}
                    filterList={true}
                    multipleSelection={false}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Trunks list */}
        {props.batchDTO?.recordingType == BatchDTORecordingTypeEnum.Single &&
        packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.packet.packet_registration.choose_trunk') +
                  getMandatorySymbol(
                    props.batchDTO.recordingType == BatchDTORecordingTypeEnum.Single && props.closingPacket!
                  )}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_trunk'}
              text={i18n.t('views.packet.packet_registration.choose_trunk')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowTrunkModalSelect(true)}
              counter={getCounterBySelected(trunkListByBatchDS)}
              disabled={!packetIsEditable(props.packetSelected)}
            />

            {/**Trunk Modal multiple select */}
            {showTrunkModalSelect ? (
              <UrstammModalSelect
                testID={'modal_trunk_multiple_select'}
                visible={showTrunkModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_trunk'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowTrunkModalSelect(false);
                  restoreListToReference(setTrunkListByBatchDS, trunkListByBatchDSReference);
                }}
                confirmButton={{
                  enabled: true,
                  text: i18n.t('generics.save'),
                  submitValue: () => {
                    setShowTrunkModalSelect(false);
                    applySavingToReference(setTrunkListByBatchDSReference, trunkListByBatchDS);
                  }
                }}
                listJSX={
                  <UrstammList
                    elementSelected={(trunkSelected: any) =>
                      selectElementFormList(trunkSelected, setTrunkListByBatchDS, trunkListByBatchDS)
                    }
                    testID={'trunk_list'}
                    textForEmptyList={i18n.t('views.trunk.trunks_not_found')}
                    list={trunkListByBatchDS}
                    titleByFunction={data => getCardTitleByTrunkNumberFas(data)}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    listSubData={trunkKeys}
                    moreItems={props.trunkProps?.getMoreTrunks}
                    filterList={true}
                    multipleSelection={true}
                    //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                    currentState={{
                      //icon: (state) => getIconByLoggingState(state),
                      color: state => getColorByTrunkState(state),
                      text: state => getTextByTrunkState(state)
                    }}
                    titleKey={'name'}
                    getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Certification list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.packet.packet_registration.choose_certification')}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_certification'}
              text={i18n.t('views.packet.packet_registration.choose_certification')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowCertificationModalSelect(true)}
              counter={getCounterBySelected(certificationList)}
              disabled={!packetIsEditable(props.packetSelected)}
            />

            {/**Trunk Modal multiple select */}
            {showCertificationModalSelect ? (
              <UrstammModalSelect
                testID={'modal_certification_multiple_select'}
                visible={showCertificationModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_certification'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowCertificationModalSelect(false);
                  props.certificationProps?.resetCertificationSelected();
                }}
                confirmButton={{
                  enabled: true,
                  text: i18n.t('generics.save'),
                  submitValue: () => {
                    setShowCertificationModalSelect(false);
                    applySavingToReference(setCertificationListReference, certificationList);
                  }
                }}
                listJSX={
                  <UrstammList
                    elementSelected={(certificationSelected: any) =>
                      selectElementFormList(certificationSelected, setCertificationList, certificationList)
                    }
                    testID={'certification_list'}
                    textForEmptyList={i18n.t('views.packet.certifications_not_found')}
                    list={certificationList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    // moreItems={props.certificationProps?.getMoreCertifications}
                    filterList={false}
                    multipleSelection={true}
                    //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                    titleKey={'name'}
                    getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Processor list */}
        {packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('generics.processor') + getMandatorySymbol(props.closingPacket!)}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                packetForm.packet.processor
                  ? packetForm.packet.processor.name
                  : i18n.t('views.packet.packet_registration.choose_processor')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProcessorModalSelect(true)}
              disabled={!packetIsEditable(props.packetSelected)}
            />

            {/**Packet processor  */}
            {showProcessorModalSelect ? (
              <UrstammModalSelect
                testID={'modal_packet_company_processor'}
                visible={showProcessorModalSelect}
                text={{
                  textInputTitle: i18n.t('views.packet.packet_registration.choose_processor'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProcessorModalSelect(false);
                }}
                confirmButton={{
                  enabled: false,
                  text: i18n.t('views.packet.no_urstamm_processor'),
                  submitValue: () => {}
                }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(processor: any) => {
                      updateProcessor(processor.item);
                    }}
                    testID={'processor_list'}
                    textForEmptyList={i18n.t('views.packet.processors_not_found')}
                    list={props.processorList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    filterList={true}
                    multipleSelection={false}
                    showHash={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/**Image picker */}
        {props.closingPacket && packetForm.packet.amountOfProducts != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammFilePicker
              testID="packet_image_picker"
              inputStyle={UrstammStyleContainer.cameraContainer}
              fontStyle={UrstammStyle.formBaseLDarkGrey}
              uri={photoPreview}
              locationProps={packetForm.packet}
              icon={{
                showIcon: true,
                iconName: 'camera',
                iconColor: ColorTheme.lightGreen
              }}
              placeholder={i18n.t('generics.upload_photo_file')}
              textInputTitle={i18n.t('views.packet.packet_registration.packet_photo')}
              uploadType={UploadType.camera}
              mandatory={true}
              deleteBase64={() => deletePhoto()}
              disabled={!packetIsEditable(props.packetSelected)}
              mediaTypeUploaded={PickerMediaType.Image}
              saveBase64={(base64: string, location: any) => {
                updatePhoto(base64, location, true);
              }}
            />

            {errors['base64File'] && errors['base64File'].length > 0
              ? errors['base64File'].map((error, idx) => {
                  return urstammUtilDisplayError(error, idx);
                })
              : null}
          </View>
        ) : null}

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="packet_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={packetForm.packet.comment ? packetForm.packet.comment : ''}
            onChangeText={(text: string) => updatePacketForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
            editable={packetIsEditable(props.packetSelected)}
          />
        </View>

        {/**Save */}
        {packetIsEditable(props.packetSelected) ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammButtonBase
              testID={'save_packet'}
              text={i18n.t('generics.save')}
              style={mainGreenButton}
              onSubmit={() => setShowClosePacketConfirm(true)}
              disabled={saveDisabled}
            />
          </View>
        ) : null}

        {/* {showNoUrstammProcessor ? (
          <UrstammModalSelect
            testID={'modal_no_processor_urstamm'}
            visible={showNoUrstammProcessor}
            text={{
              textInputTitle: i18n.t('views.packet.packet_registration.enter_processor'),
              textStyle: UrstammStyle.textTitle
              //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
            }}
            closeModal={() => {
              setPacketProcessorCustomName(packetProcessorCustomNameReference);
              updatePacketForm('processorCustomName', packetProcessorCustomNameReference, false);
              setShowNoUrstammProcessor(false);
            }}
            modalSize={{ mainContainerWidth: 0.74, mainContainerHeight: 0.4, secondaryContainerHeight: 0.2 }}
            confirmButton={{
              enabled: true,
              text: i18n.t('generics.save'),
              submitValue: () => {
                setPacketProcessorCustomNameReference(packetProcessorCustomName);
                updatePacketForm('processor', { name: packetForm.packet.processorCustomName }, props.closingPacket);
                setShowNoUrstammProcessor(false);
                setShowProcessorModalSelect(false);
              }
            }}
            listJSX={
              <>
                <UrstammInput
                  testID="packet_no_urstamm_processor"
                  style={{
                    containerViewStyle: UrstammStyleContainer.textareaContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  icon={{ showIcon: false }}
                  value={packetForm.packet.processorCustomName ? packetForm.packet.processorCustomName : ''}
                  onChangeText={(text: string) => {
                    updatePacketForm('processorCustomName', text, false);
                    setPacketProcessorCustomName(text);
                  }}
                  placeholder={i18n.t('views.packet.packet_registration.insert_processor_name_address')}
                  textInputTitle={i18n.t('generics.processor')}
                  mandatory={false}
                  multiline={true}
                  editable={packetIsEditable(props.packetSelected)}
                />
              </>
            }
          />
        ) : null} */}

        {showClosePacketConfirm && (
          <UrstammModal
            testID={'close_logging_modal'}
            text={i18n.t('views.packet.are_you_sure_want_close_packet')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={showClosePacketConfirm}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? submitPacketForm() : null, setShowClosePacketConfirm(false);
            }}
          />
        )}
      </ScrollView>
    </View>
  );
}
