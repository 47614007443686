import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import {
  ProcessorBatchDTO,
  ProcessorBatchDTORecordingTypeEnum,
  Company,
  CompanyProductType,
  CompanySurface,
  CompanyAppearance,
  Product,
} from '@services/apis/generated';
import {
  ProductDTO,
  ProductDTOCurrentStateEnum,
} from '@services/apis/generated/models/ProductDTO';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByProductState,
  getIconByProductState,
  getTextByProductSpecies,
  getTextByProductState,
  getTextByTreeOrTrunkSpecies,
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  truncate,
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

export interface ProductForm {
  product: ProductDTO;
  productPhoto?: string;
}

export interface CompanyProductTypeProps {
  companyProductTypeList?: CompanyProductType[];
  resetCompanyProductTypeSelected?: () => void;
  submitCompanyProductType?: (product: CompanyProductType) => void;
  deleteCompanyProductType?: (product: CompanyProductType) => void;
  getMoreProductType?: () => void;
}
  
export interface CompanySurfaceProps {
  companySurfaceList?: CompanySurface[];
  resetCompanySurfaceSelected?: () => void;
  submitCompanySurface?: (product: CompanySurface) => void;
  deleteCompanySurface?: (product: CompanySurface) => void;
  getMoreSurface?: () => void;
}

export interface CompanyAppearanceProps {
  companyAppearanceList?: CompanyAppearance[];
  resetCompanyAppearanceSelected?: () => void;
  submitCompanyAppearance?: (product: CompanyAppearance) => void;
  deleteCompanyAppearance?: (product: CompanyAppearance) => void;
  getMoreAppearance?: () => void;
}

export interface ProductProps {
  incomingProductList: any[];
  incomingProductSelected: (data: any) => void;
  resetProductSelected: () => void;
  confirmProductSelected: () => void;
  getMoreProduct?: () => void;
}

export interface CertificationProps {
  certificationList: any[];
  certificationSelected: (data: any) => void;
  resetCertificationSelected: () => void;
  confirmCertificationSelected: () => void;
  getMoreCertifications?: () => void;
}

export interface SpeciesProps {
  speciesList: any[];
  speciesSelected: (data: any) => void;
  resetSpeciesSelected: () => void;
  confirmSpeciesSelected: () => void;
}

export default function ProductRegistrationView(props: {
  navigation: any;
  processorBatchDTO: ProcessorBatchDTO;
  uniqueIdentifier: string;
  submitProductForm: (data: ProductForm) => void;
  companyProductTypeProps?: CompanyProductTypeProps;
  companySurfaceProps?: CompanySurfaceProps;
  companyAppearanceProps?: CompanyAppearanceProps;
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  processorList: Company[];
  cloneFromProduct?: Product;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const [uniqueIdentifier, setUniqueIdentifier] = useState<string>(props.uniqueIdentifier);
  const [showProductTypeModalSelect, setShowProductTypeModalSelect] = useState<boolean>(false);
  const [showSurfaceModalSelect, setShowSurfaceModalSelect] = useState<boolean>(false);
  const [showAppearanceModalSelect, setShowAppearanceModalSelect] = useState<boolean>(false);
  const [showTrunkModalSelect, setShowTrunkModalSelect] = useState<boolean>(false);
  const [showCertificationModalSelect, setShowCertificationModalSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any>(() => {});
  const [speciesList, setSpeciesList] = useStateWithCallbackLazy<any>(() => {});
  const [productProcessorCustomName, setProductProcessorCustomName] = useState<string>('');
  const [productProcessorCustomNameReference, setProductProcessorCustomNameReference] = useState<string>('');
  const [showNoUrstammProcessor, setShowNoUrstammProcessor] = useState<boolean>(false);

  const [previewMultiple, setPreviewMultiple] = useState<string[]>([]); //product certifications

  const [productForm, setProductForm] = useStateWithCallbackLazy<ProductForm>({
    product: {
      uniqueIdentifier: props.uniqueIdentifier ? props.uniqueIdentifier : '',
      name: props.cloneFromProduct?.name ? props.cloneFromProduct?.name : '',
      productType: props.cloneFromProduct?.productType,
      creationDate: new Date(),
      currentState: ProductDTOCurrentStateEnum.InProgress,
      comment: '',
      productSpecies: props.cloneFromProduct?.productSpecies, // TODO: check
      productCertifications: props.cloneFromProduct?.productCertifications,
      surface: props.cloneFromProduct?.surface,
      processorCustomName: props.cloneFromProduct?.processorCustomName,
      processor: props.cloneFromProduct?.processor,
      appearance: props.cloneFromProduct?.appearance,
      sumCubage: props.cloneFromProduct?.sumCubage,
    }
  });

  useEffect(() => {
    if (props.uniqueIdentifier) {
      updateProductForm('uniqueIdentifier', props.uniqueIdentifier);
      setUniqueIdentifier(props.uniqueIdentifier);
    }
    //TODO: clear form
  }, [props.uniqueIdentifier]);

  useEffect(() => {
    if (props.certificationProps?.certificationList)
      setCertificationList(props.certificationProps?.certificationList, updatedTrunkList => {});
  }, [props.certificationProps?.certificationList]);

  useEffect(() => {
    if (props.speciesProps?.speciesList)
      setSpeciesList(props.speciesProps?.speciesList, updatedList => {});
  }, [props.speciesProps?.speciesList]);

  useEffect(() => {
    if (props.cloneFromProduct) {
      saveButtonDisabled(errors, productForm);
    }
  }, [props.cloneFromProduct]);


  /**
   *
   * @param key
   * @param value
   */
  const updateProductForm = (key: string, value: any, mustValidate?: boolean): void => {
    let product = { ...productForm.product };

    const formKey = key as keyof any; //TODO: add interface
    product[formKey] = value;

    return setProductForm(
      { product: product },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true },
    sumCubage: { numeric: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    sumCubage: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProductForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedProductForm?: ProductForm) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || !updatedProductForm?.product.name) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const updateProcessor = (processor: Company) => {
    if (processor.id == -200) {
      setShowProcessorModalSelect(false);
      return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    }
    productForm.product.processorCustomName = '';
    updateProductForm('processor', processor, false);
    return setShowProcessorModalSelect(false);
  };

  const updateSumCubage = (sumCubage: number | string | undefined) => {
    if (Number.isNaN(sumCubage) || sumCubage == null || typeof sumCubage === 'undefined' || sumCubage === '') {
      updateProductForm('sumCubage', undefined, true);
    } else if (typeof sumCubage === 'string') {
      updateProductForm('sumCubage', sumCubage, true);
    } else {
      updateProductForm('sumCubage', sumCubage, true);
    }
  }

  const submitForm = () => {
    props.speciesProps?.confirmSpeciesSelected();
    props.submitProductForm(productForm);
  }

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Product unique identifier - Ex: U_BRU_001_01*/}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="product_uniqueIdentifier"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={productForm.product.uniqueIdentifier ? productForm.product.uniqueIdentifier : ''}
            onChangeText={(text: string) => {
              updateProductForm('uniqueIdentifier', text, true);
            }}
            placeholder={i18n.t('views.product.product_registration.insert_product_unique_identifier_here')}
            textInputTitle={i18n.t('views.product.product_registration.unique_identifier')}
            editable={false}
            mandatory={true}
          />

          {/**Validator */}
          {errors['uniqueIdentifier'] && errors['uniqueIdentifier'].length > 0
            ? errors['uniqueIdentifier'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Product name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="product_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={productForm.product.name ? productForm.product.name : ''}
            onChangeText={(text: string) => {
              updateProductForm('name', text, true);
            }}
            placeholder={i18n.t('views.product.product_registration.insert_product_name_here')}
            textInputTitle={i18n.t('views.product.product_registration.product_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Product type list */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.productType')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                productForm.product.productType
                  ? productForm.product.productType?.name
                  : i18n.t('views.product.product_registration.choose_product_type')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProductTypeModalSelect(true)}
              //disabled={!processorBatchForm.processorBatch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Product product type  */}
            {showProductTypeModalSelect ? (
              <UrstammModalSelect
                testID={'modal_product_company_product_type'}
                visible={showProductTypeModalSelect}
                text={{
                  textInputTitle: i18n.t('views.product.product_registration.choose_product_type'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProductTypeModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setProductType((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowProductTypeModalSelect(false), setTimeout(() => setOpenUpdateCreateProductType(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(productType: any) => {
                      updateProductForm('productType', productType.item, false);
                      setShowProductTypeModalSelect(false);
                    }}
                    showHash={false}
                    testID={'products_type_list'}
                    textForEmptyList={i18n.t('views.product.products_type_not_found')}
                    list={props.companyProductTypeProps?.companyProductTypeList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companyProductTypeProps?.getMoreProductType}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Product creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'product_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateProductForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.product.product_registration.insert_product_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** Sum cubage */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="sumCubage"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={
              productForm.product.sumCubage
                ? productForm.product.sumCubage.toString()
                : ''
            }
            onChangeText={(text: number) => updateSumCubage(text)}
            placeholder={i18n.t('views.product.product_registration.insert_sum_cubage_here')}
            textInputTitle={i18n.t('views.product.product_registration.sum_cubage')}
            keyboardType={'numeric'}
            mandatory={false}
          />

          {/**Validator */}
          {errors['sumCubage'] && errors['sumCubage'].length > 0
            ? errors['sumCubage'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Product species */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammSlideButton
              testID={'button_tree_species'}
              slideButtonList={speciesList}
              slideButtonTitle={i18n.t('generics.tree_species')}
              slideButtonRotatedTitle={i18n.t('generics.species')}
              mandatory={false}
              buttonPressed={(selected: SlideButtonOption) => {
                props.speciesProps?.speciesSelected({item: selected});
                //updateProductForm('productSpecies', speciesList, true)
              }}
            />
          </View>
        ) : null}

        {/** Appearance list */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.appearance')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_appearance'}
              text={
                productForm.product.appearance
                  ? productForm.product.appearance?.name
                  : i18n.t('views.product.product_registration.choose_appearance')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowAppearanceModalSelect(true)}
              //disabled={!processorBatchForm.processorBatch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**processorBatch Modal multiple select */}
            {showAppearanceModalSelect ? (
              <UrstammModalSelect
                testID={'modal_product_company_choose_appearance'}
                visible={showAppearanceModalSelect}
                text={{
                  textInputTitle: i18n.t('views.product.product_registration.choose_appearance'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowAppearanceModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(appearance: any) => {
                      updateProductForm('appearance', appearance.item, false);
                      setShowAppearanceModalSelect(false);
                    }}
                    showHash={false}
                    testID={'appearance_list'}
                    textForEmptyList={i18n.t('views.product.appearances_not_found')}
                    list={props.companyAppearanceProps?.companyAppearanceList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companyAppearanceProps?.getMoreAppearance}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Surface list */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.surface')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_surface'}
              text={
                productForm.product.surface
                  ? productForm.product.surface?.name
                  : i18n.t('views.product.product_registration.choose_surface')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowSurfaceModalSelect(true)}
              //disabled={!processorBatchForm.processorBatch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**processorBatch Modal multiple select */}
            {showSurfaceModalSelect ? (
              <UrstammModalSelect
                testID={'modal_product_company_choose_surface'}
                visible={showSurfaceModalSelect}
                text={{
                  textInputTitle: i18n.t('views.product.product_registration.choose_surface'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowSurfaceModalSelect(false);
                }}
                //confirmButton={{ enabled: false, text: i18n.t('views.product.product_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(surface: any) => {
                      updateProductForm('surface', surface.item, false);
                      setShowSurfaceModalSelect(false);
                    }}
                    showHash={false}
                    testID={'surface_list'}
                    textForEmptyList={i18n.t('views.product.surfaces_not_found')}
                    list={props.companySurfaceProps?.companySurfaceList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    moreItems={props.companySurfaceProps?.getMoreSurface}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Certification list */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>
                {i18n.t('views.product.product_registration.choose_certification')}
              </Text>
            </View>

            <UrstammButtonBase
              testID={'choose_certification'}
              text={i18n.t('views.product.product_registration.choose_certification')}
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowCertificationModalSelect(true)}
              counter={getCounterBySelected(certificationList)}
            />

            {/**Trunk Modal multiple select */}
            {showCertificationModalSelect ? (
              <UrstammModalSelect
                testID={'modal_certification_multiple_select'}
                visible={showCertificationModalSelect}
                text={{
                  textInputTitle: i18n.t('views.product.product_registration.choose_certification'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowCertificationModalSelect(false);
                  props.certificationProps?.resetCertificationSelected();
                }}
                confirmButton={{
                  enabled: true,
                  text: i18n.t('generics.save'),
                  submitValue: () => {
                    setShowCertificationModalSelect(false);
                    props.certificationProps?.confirmCertificationSelected();
                  }
                }}
                listJSX={
                  <UrstammList
                    elementSelected={(certificationSelected: any) =>
                      props.certificationProps?.certificationSelected(certificationSelected)
                    }
                    showHash={false}
                    testID={'certification_list'}
                    textForEmptyList={i18n.t('views.product.certifications_not_found')}
                    list={props.certificationProps?.certificationList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    moreItems={props.certificationProps?.getMoreCertifications}
                    filterList={false}
                    multipleSelection={true}
                    //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                    titleKey={'name'}
                    getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Processor list */}
        {productForm.product.sumCubage != 0 ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            {/**Title */}
            <View style={UrstammStyle.textTitleContainerFlex}>
              <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
            </View>

            <UrstammButtonBase
              testID={'choose_product_type'}
              text={
                productForm.product.processor
                  ? productForm.product.processor.name
                  : i18n.t('views.product.product_registration.choose_processor')
              }
              style={lightGreenBorderWhiteBGButton}
              onSubmit={() => setShowProcessorModalSelect(true)}
              //disabled={!processorBatchForm.processorBatch.recordingType}
              //counter={getCounterByDSSelected(deliverySheetList)}
            />

            {/**Product processor  */}
            {showProcessorModalSelect ? (
              <UrstammModalSelect
                testID={'modal_product_company_processor'}
                visible={showProcessorModalSelect}
                text={{
                  textInputTitle: i18n.t('views.product.product_registration.choose_processor'),
                  textStyle: UrstammStyle.textTitle
                  //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                }}
                closeModal={() => {
                  setShowProcessorModalSelect(false);
                }}
                confirmButton={{
                  enabled: false,
                  text: i18n.t('views.product.no_urstamm_processor'),
                  submitValue: () => {}
                }}
                listJSX={
                  <UrstammList
                    titleKey={'name'}
                    elementSelected={(processor: any) => {
                      updateProcessor(processor.item);
                    }}
                    showHash={false}
                    testID={'processor_list'}
                    textForEmptyList={i18n.t('views.product.processors_not_found')}
                    list={props.processorList}
                    listStyle={{
                      mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                      mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                      mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                      bottomContainerViewStyle: UrstammStyleList.cardSubData,
                      bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                      paddingBottom: 4
                    }}
                    //listSubData={keys}
                    filterList={true}
                    multipleSelection={false}
                  />
                }
              />
            ) : null}
          </View>
        ) : null}

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="product_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={productForm.product.comment ? productForm.product.comment : ''}
            onChangeText={(text: string) => updateProductForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_product'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => submitForm()}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>

        {showNoUrstammProcessor ? (
          <UrstammModalSelect
            testID={'modal_no_processor_urstamm'}
            visible={showNoUrstammProcessor}
            text={{
              textInputTitle: i18n.t('views.product.product_registration.enter_processor'),
              textStyle: UrstammStyle.textTitle
              //textInputSubTitle: i18n.t('views.processor_batch.processor_batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
            }}
            closeModal={() => {
              setProductProcessorCustomName(productProcessorCustomNameReference);
              updateProductForm('processorCustomName', productProcessorCustomNameReference, false);
              setShowNoUrstammProcessor(false);
            }}
            modalSize={{ mainContainerWidth: 0.74, mainContainerHeight: 0.4, secondaryContainerHeight: 0.2 }}
            confirmButton={{
              enabled: true,
              text: i18n.t('generics.save'),
              submitValue: () => {
                setProductProcessorCustomNameReference(productProcessorCustomName);
                updateProductForm('processor', { name: productForm.product.processorCustomName }, false);
                setShowNoUrstammProcessor(false);
                setShowProcessorModalSelect(false);
              }
            }}
            listJSX={
              <>
                <UrstammInput
                  testID="product_no_urstamm_processor"
                  style={{
                    containerViewStyle: UrstammStyleContainer.textareaContainer,
                    textStyle: UrstammStyle.formBaseLDarkGrey
                  }}
                  icon={{ showIcon: false }}
                  value={productForm.product.processorCustomName ? productForm.product.processorCustomName : ''}
                  onChangeText={(text: string) => {
                    updateProductForm('processorCustomName', text, false);
                    setProductProcessorCustomName(text);
                  }}
                  placeholder={i18n.t('views.product.product_registration.insert_processor_name_address')}
                  textInputTitle={i18n.t('generics.processor')}
                  mandatory={false}
                  multiline={true}
                />
              </>
            }
          />
        ) : null}
      </ScrollView>
    </View>
  );
}
