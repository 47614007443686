/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EntityWithFileDTOProduct,
  Product,
  ProductDTO,
} from '../models';
import {
    EntityWithFileDTOProductFromJSON,
    EntityWithFileDTOProductToJSON,
    ProductFromJSON,
    ProductToJSON,
    ProductDTOFromJSON,
    ProductDTOToJSON,
} from '../models';

export interface CreateProductCustomRequest {
    entityWithFileDTOProduct: EntityWithFileDTOProduct;
}

export interface DownloadProductPhotoRequest {
    id: number;
}

export interface GetProductCustomRequest {
    id: number;
}

export interface GetProductsByBatchRequest {
    processorShippingNoteId?: number,
    batchId: number;
    sortBy?: string;
    direction?: GetProductsByBatchDirectionEnum;
}

export interface GetProductsByProcessorShippingNoteRequest {
    processorShippingNoteId: number;
}

export interface UpdateProductCustomRequest {
    id: number;
    entityWithFileDTOProduct: EntityWithFileDTOProduct;
}

/**
 * 
 */
export class CustomProductResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Product connecting to Company of logged user.
     * Create a Product
     */
    async createProductCustomRaw(requestParameters: CreateProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.entityWithFileDTOProduct === null || requestParameters.entityWithFileDTOProduct === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOProduct','Required parameter requestParameters.entityWithFileDTOProduct was null or undefined when calling createProductCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOProductToJSON(requestParameters.entityWithFileDTOProduct),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Create a new Product connecting to Company of logged user.
     * Create a Product
     */
    async createProductCustom(requestParameters: CreateProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.createProductCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo corresponding to Product identified by given id.
     * Download photo of Product
     */
    async downloadProductPhotoRaw(requestParameters: DownloadProductPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadProductPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/products/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download photo corresponding to Product identified by given id.
     * Download photo of Product
     */
    async downloadProductPhoto(requestParameters: DownloadProductPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadProductPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Product identified by given id (must belong to Company of logged user).
     * Get a specific Product
     */
    async getProductCustomRaw(requestParameters: GetProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDTOFromJSON(jsonValue));
    }

    /**
     * Get Product identified by given id (must belong to Company of logged user).
     * Get a specific Product
     */
    async getProductCustom(requestParameters: GetProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDTO> {
        const response = await this.getProductCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Product belonging to Company of logged User.
     * Get products for a Batch
     */
    async getProductsByBatchRaw(requestParameters: GetProductsByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling getProductsByBatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.processorShippingNoteId !== undefined) {
            queryParameters['processorShippingNoteId'] = requestParameters.processorShippingNoteId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/products/batch/{batchId}`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Get a page of Product belonging to Company of logged User.
     * Get products for a Batch
     */
    async getProductsByBatch(requestParameters: GetProductsByBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getProductsByBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of Products for ProcessorShippingNote with given id (must belong to Company of logged user).
     * Get a list of Products for ProcessorShippingNote
     */
    async getProductsByProcessorShippingNoteRaw(requestParameters: GetProductsByProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        if (requestParameters.processorShippingNoteId === null || requestParameters.processorShippingNoteId === undefined) {
            throw new runtime.RequiredError('processorShippingNoteId','Required parameter requestParameters.processorShippingNoteId was null or undefined when calling getProductsByProcessorShippingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/products/processorShippingNote/{processorShippingNoteId}`.replace(`{${"processorShippingNoteId"}}`, encodeURIComponent(String(requestParameters.processorShippingNoteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Get a list of Products for ProcessorShippingNote with given id (must belong to Company of logged user).
     * Get a list of Products for ProcessorShippingNote
     */
    async getProductsByProcessorShippingNote(requestParameters: GetProductsByProcessorShippingNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getProductsByProcessorShippingNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Product identified by given id (must belong to Company of logged user).
     * Partial update a Product
     */
    async updateProductCustomRaw(requestParameters: UpdateProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProductCustom.');
        }

        if (requestParameters.entityWithFileDTOProduct === null || requestParameters.entityWithFileDTOProduct === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOProduct','Required parameter requestParameters.entityWithFileDTOProduct was null or undefined when calling updateProductCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOProductToJSON(requestParameters.entityWithFileDTOProduct),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Partial update Product identified by given id (must belong to Company of logged user).
     * Partial update a Product
     */
    async updateProductCustom(requestParameters: UpdateProductCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.updateProductCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetProductsByBatchDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetProductsByBatchDirectionEnum = typeof GetProductsByBatchDirectionEnum[keyof typeof GetProductsByBatchDirectionEnum];
