import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  setOutgoingProcessorShippingNoteList,
  setOutgoingProcessorShippingNoteListSort,
  setOutgoingProcessorShippingNotePage,
  setOutgoingProcessorShippingNoteTotalPages
} from '@redux/features/processor-shipping-note/outgoingProcessorShippingNoteSlice';
import { RootState } from '@redux/store';

import { customProcessorShippingNoteResourceApi, listSize } from '@services/apis/ApiConfiguration';

import OutgoingProcessorShippingNoteListView from '@components/views/processor-shipping-note/OutgoingProcessorShippingNoteListView';
import { useFocusEffect } from '@react-navigation/native';
import {
  CustomProcessorShippingNoteCriteriaCompanyFilterEnum,
  PageProcessorShippingNoteDTO,
  ProcessorShippingNote
} from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function OutgoingProcessorShippingNoteListContainer({
  navigation,
  route
}: RootStackScreenProps<'OutgoingProcessorShippingNoteList'>) {
  const dispatch = useDispatch();
  
  //REDUX STATE
  const {
    list,
    page,
    totalPages,
    sortBy
  } = useSelector((state: RootState) => state.persistedReducer.outgoingProcessorShippingNote);
  
  // Local State
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  // Get Processor Shipping Note List
  const getProcessorShippingNoteList = useCallback((order?: ButtonSelect[]) => {
    const sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));
    const criteriaFilter = CustomProcessorShippingNoteCriteriaCompanyFilterEnum.Creator;
    customProcessorShippingNoteResourceApi
      .getPageProcessorShippingNotesForMyCompany({
        sort: sortBy,
        customProcessorShippingNoteCriteria: { currentState: {}, companyFilter: criteriaFilter },
        page,
        size: listSize
      })
      .then((processorShippingNoteList: PageProcessorShippingNoteDTO) => {
        dispatch(changeLoaderStatus(false));
        if (processorShippingNoteList) {
          dispatch(setOutgoingProcessorShippingNoteTotalPages(processorShippingNoteList.totalPages));
          dispatch(setOutgoingProcessorShippingNoteList(processorShippingNoteList.content));
        }
      })
      .catch(async (error) => {
        dispatch(changeLoaderStatus(false));
        const errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        const errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, [dispatch, page]);

  useFocusEffect(
    useCallback(() => {
      getProcessorShippingNoteList(sortBy);
    }, [dispatch, route.params, page, sortBy])
  );

  // apply order
  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setOutgoingProcessorShippingNoteListSort(orderSelected));
    setShowSortBy(false);
  };

  // navigate to details
  const navigateToProcessorShippingNoteDetails = (processorShippingNote: ProcessorShippingNote) => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteDetails(navigation, processorShippingNote);
  };

  // navigate to registration
  const navigateToProcessorShippingNoteRegistration = () => {
    UrstammNavigationHelper.navigateToProcessorShippingNoteRegistration(navigation, list);
  };

  // get more items
  const getMoreItems = (): void => {
    if (page < totalPages - 1) {
      dispatch(setOutgoingProcessorShippingNotePage(page + 1));
    }
  };

  /**
   * Settings for corner buttons
   */
  const cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText: sortBy.length > 0 && sortBy[0] ? sortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.outgoing_goods'),
      icon: <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToProcessorShippingNoteRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('generics.outgoing_goods')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <OutgoingProcessorShippingNoteListView
          navigation={navigation}
          processorShippingNoteList={list}
          modalVisible={false}
          moreItems={getMoreItems}
          processorShippingNoteSelected={(processorShippingNote: ProcessorShippingNote) =>
            navigateToProcessorShippingNoteDetails(processorShippingNote)
          }
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
