/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { setLoggingList } from '@redux/features/logging/loggingSlice';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { setPileList } from '@redux/features/pile/pileSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { storeLoggingPermitPhoto, storeLoggingPhoto } from '../../../../utils/classes/UrstammUtilityFile';
import type {
    CustomLoggingCriteria,
    EntityWithFileDTOLogging,
    Logging,
    LoggingDTO,
    PageLogging,
} from '../models';
import {
    CustomLoggingCriteriaToJSON,
    EntityWithFileDTOLoggingToJSON,
    LoggingDTOCurrentStateEnum,
    LoggingDTOFromJSON,
    LoggingFromJSON,
    PageLoggingFromJSON,
    PileCurrentStateEnum,
    PileWoodTypeEnum
} from '../models';
import * as runtime from '../runtime';

export interface CreateLoggingCustomRequest {
    entityWithFileDTOLogging: EntityWithFileDTOLogging;
}

export interface DeleteLoggingCustomRequest {
    id: number;
}

export interface DownloadLoggingPermitRequest {
    id: number;
}

export interface DownloadLoggingPhotoRequest {
    id: number;
}

export interface GetAllLoggingsForMyCompanyRequest {
    customLoggingCriteria: CustomLoggingCriteria;
}

export interface GetLoggingCustomRequest {
    id: number;
}

export interface GetPageLoggingsForMyCompanyRequest {
    customLoggingCriteria: CustomLoggingCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateLoggingCustomRequest {
    id: number;
    entityWithFileDTOLogging: EntityWithFileDTOLogging;
}

export interface DeleteLoggingPhotoRequest {
    id: number;
}

/**
 * 
 */
export class CustomLoggingResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Logging connecting to Company of logged user.
     * Create a Logging
     */
    async createLoggingCustomRaw(requestParameters: CreateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.entityWithFileDTOLogging === null || requestParameters.entityWithFileDTOLogging === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOLogging','Required parameter requestParameters.entityWithFileDTOLogging was null or undefined when calling createLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOLoggingToJSON(requestParameters.entityWithFileDTOLogging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     * Create a new Logging connecting to Company of logged user.
     * Create a Logging
     */
    async createLoggingCustom(rdxOfflineSyncList, dispatch: Dispatch<AnyAction>, addOfflineObjectSync: ActionCreatorWithPayload<any, string>, requestParameters: CreateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        let networkOk = await NetworkHelper.isNetworkOk();
        if (networkOk){
            requestParameters.entityWithFileDTOLogging.entity!.id = undefined;
            const response = await this.createLoggingCustomRaw(requestParameters, initOverrides);
            return await response.value();
        }else{
            const permitBase64 = requestParameters.entityWithFileDTOLogging.base64File!;
            if(permitBase64){
                storeLoggingPermitPhoto(requestParameters.entityWithFileDTOLogging.entity!.id, permitBase64);
                requestParameters.entityWithFileDTOLogging.base64File = undefined;
            }
            
            const photoBase64 = requestParameters.entityWithFileDTOLogging.loggingPhotoBase64File!;
            if(photoBase64){
                storeLoggingPhoto(requestParameters.entityWithFileDTOLogging.entity!.id, photoBase64);
                requestParameters.entityWithFileDTOLogging.loggingPhotoBase64File = undefined
            }

            const newPile = {
                entity: {
                    id: DateHelper.nowTimestamp(),
                    logging: {
                        id: requestParameters.entityWithFileDTOLogging.entity?.id,
                        name: requestParameters.entityWithFileDTOLogging.entity?.name
                    },
                    name: 'Polter 1',
                    automaticallyCreated: true,
                    creationDate: new Date(),
                    woodType: PileWoodTypeEnum.Timber,
                    currentState: PileCurrentStateEnum.InProgress,
                    // Add other necessary properties for the pile
                },
            };

            if (!requestParameters.entityWithFileDTOLogging.entity) {
                throw new runtime.RequiredError('entity', 'Entity is required in entityWithFileDTOLogging');
            }

            requestParameters.entityWithFileDTOLogging.entity.onlineSync = true;

            dispatch(
                addOfflineObjectSync({
                  entity: requestParameters.entityWithFileDTOLogging,
                  type: OfflineSyncTypeEnum.INSERT,
                  class: OfflineSyncClassEnum.LOGGING,
                  name: requestParameters.entityWithFileDTOLogging.entity?.name
                })
              );
              dispatch(setLoggingList([{
                comment: requestParameters.entityWithFileDTOLogging.entity.comment,
                creationDate: requestParameters.entityWithFileDTOLogging.entity.creationDate,
                id: requestParameters.entityWithFileDTOLogging.entity.id,
                name: requestParameters.entityWithFileDTOLogging.entity.name,
                estimatedVolume: requestParameters.entityWithFileDTOLogging.entity.estimatedVolume,
                currentState: LoggingDTOCurrentStateEnum.InProgress,
                permitUrl: permitBase64,
                loggingPhoto: {
                    photoUrl: photoBase64,
                    latitude: requestParameters.entityWithFileDTOLogging.entity.loggingPhoto?.latitude,
                    longitude: requestParameters.entityWithFileDTOLogging.entity.loggingPhoto?.longitude,
                }
            }]));

              dispatch(
                addOfflineObjectSync({
                  entity: newPile,
                  type: OfflineSyncTypeEnum.INSERT,
                  class: OfflineSyncClassEnum.PILE,
                  name: newPile.entity?.name
                })
              );
              dispatch(setPileList([newPile.entity]));
        }

        return {
            ...requestParameters.entityWithFileDTOLogging.entity,
            comment: requestParameters.entityWithFileDTOLogging.entity!.comment || undefined,
            creationDate: requestParameters.entityWithFileDTOLogging.entity!.creationDate,
            currentState: requestParameters.entityWithFileDTOLogging.entity!.currentState,
            estimatedVolume: requestParameters.entityWithFileDTOLogging.entity!.estimatedVolume,
            loggingPhoto: requestParameters.entityWithFileDTOLogging.entity!.loggingPhoto || undefined,
            name: requestParameters.entityWithFileDTOLogging.entity!.name
          };
    }

    /**
     * Delete Logging identified by given id.
     * Delete a Logging
     */
    async deleteLoggingCustomRaw(requestParameters: DeleteLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Logging identified by given id.
     * Delete a Logging
     */
    async deleteLoggingCustom(requestParameters: DeleteLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLoggingCustomRaw(requestParameters, initOverrides);
    }

    /**
     * Download permit image/document corresponding to Logging identified by given id.
     * Download permit of Logging
     */
    async downloadLoggingPermitRaw(requestParameters: DownloadLoggingPermitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadLoggingPermit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}/download/permit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download permit image/document corresponding to Logging identified by given id.
     * Download permit of Logging
     */
    async downloadLoggingPermit(requestParameters: DownloadLoggingPermitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadLoggingPermitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo image corresponding to Logging identified by given id.
     * Download photo of Logging
     */
    async downloadLoggingPhotoRaw(requestParameters: DownloadLoggingPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadLoggingPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/logging/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download permit image/document corresponding to Logging identified by given id.
     * Download permit of Logging
     */
    async downloadLoggingPhoto(requestParameters: DownloadLoggingPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadLoggingPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list (page) of Logging belonging to Company of logged User.
     * Get all loggings for my Company
     */
    async getAllLoggingsForMyCompanyRaw(requestParameters: GetAllLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Logging>>> {
        if (requestParameters.customLoggingCriteria === null || requestParameters.customLoggingCriteria === undefined) {
            throw new runtime.RequiredError('customLoggingCriteria','Required parameter requestParameters.customLoggingCriteria was null or undefined when calling getAllLoggingsForMyCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/company/my/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomLoggingCriteriaToJSON(requestParameters.customLoggingCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoggingFromJSON));
    }

    /**
     * Get a list (page) of Logging belonging to Company of logged User.
     * Get all loggings for my Company
     */
    async getAllLoggingsForMyCompany(requestParameters: GetAllLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Logging>> {
        const response = await this.getAllLoggingsForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Logging identified by given id (must belong to Company of logged user).
     * Get a specific Logging
     */
    async getLoggingCustomRaw(requestParameters: GetLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingDTOFromJSON(jsonValue));
    }

    /**
     * Get Logging identified by given id (must belong to Company of logged user).
     * Get a specific Logging
     */
    async getLoggingCustom(requestParameters: GetLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingDTO> {
        const response = await this.getLoggingCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Logging belonging to Company of logged User.
     * Get a page of loggings for my Company
     */
    async getPageLoggingsForMyCompanyRaw(requestParameters: GetPageLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageLogging>> {
        if (requestParameters.customLoggingCriteria === null || requestParameters.customLoggingCriteria === undefined) {
            throw new runtime.RequiredError('customLoggingCriteria','Required parameter requestParameters.customLoggingCriteria was null or undefined when calling getPageLoggingsForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomLoggingCriteriaToJSON(requestParameters.customLoggingCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLoggingFromJSON(jsonValue));
    }

    /**
     * Get a page of Logging belonging to Company of logged User.
     * Get a page of loggings for my Company
     */
    async getPageLoggingsForMyCompany(requestParameters: GetPageLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageLogging> {
        const response = await this.getPageLoggingsForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Logging identified by given id (must belong to Company of logged user).
     * Partial update a Logging
     */
    async updateLoggingCustomRaw(requestParameters: UpdateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLoggingCustom.');
        }

        if (requestParameters.entityWithFileDTOLogging === null || requestParameters.entityWithFileDTOLogging === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOLogging','Required parameter requestParameters.entityWithFileDTOLogging was null or undefined when calling updateLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOLoggingToJSON(requestParameters.entityWithFileDTOLogging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     * Partial update Logging identified by given id (must belong to Company of logged user).
     * Partial update a Logging
     */
    async updateLoggingCustom(rdxOfflineSyncList, dispatch: Dispatch<AnyAction>, addOfflineObjectSync: ActionCreatorWithPayload<any, string>, requestParameters: UpdateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        let networkOk = await NetworkHelper.isNetworkOk();
        if(networkOk) {
            const response = await this.updateLoggingCustomRaw(requestParameters, initOverrides);
            return await response.value();
        }else{
            const permitBase64 = requestParameters.entityWithFileDTOLogging.base64File!;
            if(permitBase64){
                storeLoggingPermitPhoto(requestParameters.entityWithFileDTOLogging.entity!.id, permitBase64);
                requestParameters.entityWithFileDTOLogging.base64File = undefined;
            }
            
            const photoBase64 = requestParameters.entityWithFileDTOLogging.loggingPhotoBase64File!;
            if(photoBase64){
                storeLoggingPhoto(requestParameters.entityWithFileDTOLogging.entity!.id, photoBase64);
                requestParameters.entityWithFileDTOLogging.loggingPhotoBase64File = undefined
            }
              dispatch(
                addOfflineObjectSync({
                  entity: requestParameters.entityWithFileDTOLogging,
                  type: OfflineSyncTypeEnum.UPDATE,
                  class: OfflineSyncClassEnum.LOGGING,
                  name: requestParameters.entityWithFileDTOLogging.entity?.name
                })
              );
              dispatch(setLoggingList([requestParameters.entityWithFileDTOLogging.entity]));
        }

        return requestParameters.entityWithFileDTOLogging.entity!;
    }

    /**
     * Delete Logging photo by given id.
     * Delete a Logging photo
     */
    async deleteLoggingPhotoRaw(requestParameters: DeleteLoggingPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/logging-photo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Logging identified by given id.
     * Delete a Logging
     */
    async deleteLoggingPhotoCustom(requestParameters: DeleteLoggingPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLoggingPhotoRaw(requestParameters, initOverrides);
    }
}
