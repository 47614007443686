import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import TreeRegistrationView, { TreeForm } from '@components/views/tree/TreeRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { addOfflineObjectSync } from '@redux/features/offlineSync/offlineSyncSlice';
import { setNumberOfTrees } from '@redux/features/utility/forestSlice';
import { listSize } from '@services/apis/ApiConfiguration';
import { FullAvailableLoggingResourceApi } from '@services/apis/FullAvailableLoggingResourceApi';
import { FullAvailableTreeResourceApi } from '@services/apis/FullAvailableTreeResourceApi';
import {
  GetTreesByLoggingDirectionEnum,
  Logging,
  LoggingStateEnumFilterEqualsEnum,
  PageLogging,
  TreeDTO,
  TreeDTORecordingTypeEnum,
  TreeStateStateEnum
} from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { setLoggingList, setLoggingPage, setTotalPages } from '../../redux/features/logging/loggingSlice';
import { resetTreeList } from '../../redux/features/tree/treeSlice';
import { RootState } from '../../redux/store';
import { formatPhotoNumber, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function TreeRegistrationContainer({ navigation, route }: RootStackScreenProps<'TreeRegistration'>) {
  //Redux
  const rdxOfflineSyncList = useSelector((state: RootState) => state.persistedReducer.offlineSync.updateList);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);

  const rdxLoggingList = useSelector((state: RootState) => state.persistedReducer.logging.list);
  const rdxLoggingCompleteList = useSelector((state: RootState) => state.persistedReducer.logging.completeList);
  const rdxLoggingPage = useSelector((state: RootState) => state.persistedReducer.logging.page);
  const rdxLoggingTotalPages = useSelector((state: RootState) => state.persistedReducer.logging.totalPages);

  const rdxTreeCompleteList = useSelector((state: RootState) => state.persistedReducer.tree.completeList);

  const dispatch = useDispatch();

  const [clearForm, setClearForm] = useState<boolean>(false);
  const [resetRecordingTypeOptions, setResetRecordingTypeOptions] = useState<boolean>(false);
  const [photoNumber, setPhotoNumber] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [newTreeRecordingFromDrawer, setNewTreeRecordingFromDrawer] = useState<boolean>(false);

  const [treeList, setTreeList] = useStateWithCallbackLazy<TreeDTO[]>([]);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: newTreeRecordingFromDrawer ? false : true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      // New tree recording (drawer menu)
      if (route.params?.newTreeRecordingFromDrawer) {
        setClearForm(true);
        setResetRecordingTypeOptions(true);
        setNewTreeRecordingFromDrawer(route.params?.newTreeRecordingFromDrawer);
        getLoggingList();
      }
      if (route.params?.loggingSelected) {
        getTreeList(route.params?.loggingSelected);
      }
      return () => {
        setClearForm(false);
        setResetRecordingTypeOptions(false);
        setTreeList([], () => {});
      };
    }, [route.params, route.params?.loggingSelected])
  );

  useEffect(() => {
    getLoggingList();
  }, [rdxLoggingPage]);

  const goBack = async (): Promise<void> => {
    dispatch(resetTreeList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToTreeList(navigation, route.params?.loggingSelected!, true);
  };

  const getLoggingList = (order?: ButtonSelect[]): void => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    FullAvailableLoggingResourceApi.getPageLoggingsForMyCompany(rdxLoggingCompleteList, {
      sort: sortBy,
      customLoggingCriteria: {
        currentState: {
          equals: LoggingStateEnumFilterEqualsEnum.InProgress
        }
      },
      page: rdxLoggingPage,
      size: listSize
    })
      .then((loggingList: PageLogging) => {
        dispatch(changeLoaderStatus(false));

        if (loggingList) {
          dispatch(setTotalPages(loggingList.totalPages!));
          dispatch(setLoggingList(loggingList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getMoreItemsLogging = (): void => {
    if (rdxLoggingPage < rdxLoggingTotalPages - 1) {
      dispatch(setLoggingPage(rdxLoggingPage + 1));
    }
  };

  const generatePhotoNumberByTrees = (treeList): void => {
    let firstLetter: string =
      rdxUserExtendedMe.photoLetter && rdxUserExtendedMe.photoLetter.length > 0
        ? rdxUserExtendedMe.photoLetter?.slice(0, 1)
        : '?';
    let photoNumberList: string[] = [];

    if (treeList.length > 0) {
      /*
       *Deleting the first letter from the photoNumber and pushing the progressive numbers into photoNumberList
       */
      treeList.forEach(tree => {
        let id = tree.photoNumber && tree.photoNumber.trim().length > 0 ? tree.photoNumber?.slice(1) : undefined;
        if (id) photoNumberList.push(id!);
      });

      if (photoNumberList.length > 0) {
        //Comparing values using the reduce function and return the highest
        let maxId: string = photoNumberList.reduce((prev, current) => {
          return prev > current ? prev : current;
        });
        maxId = formatPhotoNumber((Number(maxId) + 1).toString());

        //Finally combining the two variables (string);
        return setPhotoNumber(firstLetter + maxId);
      }
    }
    //Default value
    return setPhotoNumber(firstLetter + '001');
  };

  const treesNumber = useSelector((state: RootState) => state.persistedReducer.forest.numberOfTrees);

  const saveAndContinue = (form: TreeForm, navigateToList?: boolean): void => {
    setLoading(true);
    setClearForm(false);
    setResetRecordingTypeOptions(false);

    //Adding logging selected to form
    if (!newTreeRecordingFromDrawer) {
      const loggingSelected1 = route.params?.loggingSelected;
      form.tree.logging = {
        id: loggingSelected1?.id,
        name: loggingSelected1?.name,
        currentState: loggingSelected1?.currentState
      };
    } else {
      const loggingSelected2 = form.tree.logging;
      form.tree.logging = {
        id: loggingSelected2?.id,
        name: loggingSelected2?.name || ''
      };
    }
    form.tree.photoNumber =
      form.tree.recordingType == TreeDTORecordingTypeEnum.Single ? form.tree.photoNumber : undefined;

    form.tree.creationDate = new Date();
    form.tree.uuid = '111';
    form.tree.currentState = TreeStateStateEnum.Cut;

    dispatch(changeLoaderStatus(true));

    FullAvailableTreeResourceApi.createTreeCustom(rdxOfflineSyncList, dispatch, addOfflineObjectSync, {
      entityWithFileDTOTreeDTO: {
        entity: form.tree,
        base64File: form.base64File
      }
    })
      .then(res => {
        dispatch(changeLoaderStatus(false));

        if (res) {
          dispatch(setNumberOfTrees(treesNumber + 1));
          setLoading(false);
          setClearForm(true);
          incrementPhotoNumber();
          if (navigateToList) {
            dispatch(resetTreeList());
            setClearForm(true);
            setResetRecordingTypeOptions(true);
            if (!newTreeRecordingFromDrawer)
              UrstammNavigationHelper.navigateToTreeList(navigation, route.params?.loggingSelected!, true);
          }
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        setLoading(false);
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  function numberOfDigits(number) {
    if (number >= 10) {
      return numberOfDigits(number / 10) + 1;
    }
    return 1;
  }

  const incrementPhotoNumber = (): void => {
    let firstLetter: string = rdxUserExtendedMe.photoLetter ? rdxUserExtendedMe.photoLetter?.slice(0, 1) : '?';
    let finalNumber = NumberHelper.incrementPhotoNumber(photoNumber);
    setPhotoNumber(firstLetter + finalNumber);
  };

  /**
   * Function used to recalculate photo number using the selected logging
   * @param loggingSelected
   */
  const getPhotoNumberByLogging = (loggingSelected: Logging) => {
    getTreeList(loggingSelected);
  };

  /**
   * Getting tree list using loggingId
   * @param loggingSelected
   * @param order
   */
  const getTreeList = (loggingSelected: Logging, order?: ButtonSelect[]): void => {
    let sort = order && order.length > 0 ? order[0].sort?.sortBy : 'creationDate';
    let direction = order && order.length > 0 ? order[0].sort?.direction : 'DESC';

    if (loggingSelected && loggingSelected.id) {
      dispatch(changeLoaderStatus(true));

      FullAvailableTreeResourceApi.getTreesByLogging(rdxTreeCompleteList, {
        loggingId: loggingSelected.id,
        sortBy: sort!,
        direction: direction! as GetTreesByLoggingDirectionEnum
      })
        .then((list: TreeDTO[]) => {
          dispatch(changeLoaderStatus(false));

          if (list) {
            setTreeList(list, updatedTreeList => {
              generatePhotoNumberByTrees(updatedTreeList);
            });
          }
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'tree_title'}
          text={i18n.t('views.tree.tree_registration.new_tree')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={
        route.params?.loggingSelected
          ? [
              {
                title: i18n.t('generics.name'),
                value: route.params?.loggingSelected.name
              }
            ]
          : undefined
      }
      view={
        <TreeRegistrationView
          navigation={navigation}
          loggingSelected={route.params?.loggingSelected!}
          photoNumber={photoNumber}
          saveAndContinue={(form: TreeForm) => saveAndContinue(form)}
          submitTreeForm={(form: any) => saveAndContinue(form, true)}
          clearForm={clearForm}
          resetRecordingType={resetRecordingTypeOptions}
          showLoggingList={newTreeRecordingFromDrawer}
          loggingList={rdxLoggingList}
          moreItemsLogging={getMoreItemsLogging}
          loggingSelectedFormList={(logging: Logging) => getPhotoNumberByLogging(logging)}
          loading={loading}
          setLoading={setLoading}
        />
      }
    />
  );
}
